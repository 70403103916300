import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { callApi, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import { PiStudent } from "react-icons/pi";
import { MdAddCircleOutline, MdEdit, MdSubject } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import OneFloatGoal from "../ones/OneFloatGoal";
import OneSupportedProvider from "../ones/OneSupportedProvider";
import { MdAdd, MdCheckCircle, MdCheckCircleOutline, MdOutlineDelete } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState();

  //..
  const [ password, setPassword ] = useState("");
  const [ confirm, setConfirm ] = useState("");
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  
  //..

  async function getAgentData() {
    await callApi(
      "get_staff_data.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setData(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  

  

  async function update() {
    if(!loading) {
      //..
      if(password.trim().length >= 6) {
        if(password === confirm) {
          //..
          setLoading(true);
          await callApi(
            "change_staff_password.php", 
            { 
              id, 
              password,
            }
          ).then(response => {
            if(response.status === 1) {
              //successful
              setOpSuccessful(true);
              appContext.getAllStaff();
              appContext.tellMessage("Successful");
            } else {
              appContext.tellError(response.msg)
            }
          })
          setLoading(false);
          //..
        } else {
          appContext.tellError("Confirm password correctly");
        }
      } else {
        appContext.tellError("Password must have atleast 6 characters");
      }
      //..
    } else {
      appContext.tellInfo("Loading, please wait....")
    }
  }

  async function init() {
    //run initializations here
    setReady(false);
    await getAgentData();
    
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [ id ])

 

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])
  
  if(ready && data) {
    if(
      appContext.currentUserData.role === 'admin' || 
      Number(id) === Number(appContext.currentUserData.id)
    ) {
      //..
      return (
        <div 
          className="container"
          
        >
          <div className="mFormType1">
            
            <h6 className="section-title">Update Staff's Password</h6>
          
            <div className="body">
              <div className="row">
                {
                  (opSuccessful) ?
                  <div className="col-md-12">
                    <div
                      className="bg-background text-center"
                      style={{
                        padding: "20px",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <h6
                        className="font-semi-bold text-success"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <MdCheckCircle size={60}/>
                        <br/>
                        Staff's password was updated successfully
                      </h6>
                    </div>
                  </div>
                  :""
                }
    
                <div className="mb-3 col-6">
                  <label className="form-label">New Password*</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setOpSuccessful(false);
                    }}
                  />
                  <div className="form-text">Will be used by the member to login</div>
                </div>

                <div className="mb-3 col-6">
                  <label className="form-label">Confirm*</label>
                  <input
                    type="password"
                    className="form-control"
                    value={confirm}
                    onChange={(e) => {
                      setConfirm(e.target.value);
                      setOpSuccessful(false);
                    }}
                  />
                </div>
    
                <div className="col-md-12 text-end">
                  <button
                    className="btn btn-primary btn-padded mSupportLoading"
                    onClick={() => {
                      appContext.activateDialog({
                        message: `Password for user ${String(data.fullname).toUpperCase()} will be updated, continue?`,
                        onConfirm: update,
                      })
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
    
          </div>
          
    
        </div>
      )
      //..
    } else {
      //no permission
    }
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}