import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import OneCashRequest from '../ones/OneCashRequest';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const[ ready, setReady ] = useState(false);

  const [ pendingCount, setPendingCount ] = useState(0);
  const [ confirmedCount, setConfirmedCount ] = useState(0);
  const [ selectedStatus, setSelectedStatus ] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getAllTpsfCashRequests();
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(appContext.allTpsfCashRequests) {

      //count agents and tpsf
      let _pendingCount = 0;
      let _confirmedCount = 0;

      for (const _req of appContext.allTpsfCashRequests) {
        if(_req.status === 'pending') {
          _pendingCount += 1;
        }

        if(_req.status === 'confirmed') {
          _confirmedCount += 1;
        }
      }

      setPendingCount(_pendingCount);
      setConfirmedCount(_confirmedCount);

    }
  }, [ appContext.allTpsfCashRequests ])
  
  if(ready) {
    return (
      <div 
        className="container"
      >
        <div className="card">
          
          <div className="card-body">
            
            
            <h1 className="main-section-title">All TPSFs' Cash Requests</h1>

            <div className="row" style={{ marginBottom: "20px" }}>
              <div className="col-6">
                <div 
                  className=""
                  style={{
                    width:"100%",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "2px solid var(--secondaryColor)"
                  }}
                >
                  <h5
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    {confirmedCount}
                  </h5>
                  <h6 style={{ fontSize: "12px" }}>Confirmed</h6>
                </div>
              </div>

              <div className="col-6">
                <div 
                  className=""
                  style={{
                    width:"100%",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "2px solid var(--secondaryColor)"
                  }}
                >
                  <h5
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    {pendingCount}
                  </h5>
                  <h6 style={{ fontSize: "12px" }}>Pending</h6>
                </div>
              </div>

            </div>

            <hr/>   

            <div 
              className="d-flex mb-4"
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "120px",
                  padding: "5px",
                }}
              >
                <label className="form-label mNoMargin font-semi-bold" style={{ fontSize: "12px" }}>Status</label>
                <select
                  className="form-control"
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value)
                  }}
                >
                  <option value="">All</option>
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                </select>
              </div>

            </div>  

            {
              (appContext.allTpsfCashRequests && appContext.allTpsfCashRequests.length > 0) ?
              appContext.allTpsfCashRequests.map((item, i) => {
                if(selectedStatus) {
                  if(item.status === selectedStatus) {
                    return (
                      <OneCashRequest key={i} data={item}/>
                    )
                  }
                } else {
                  return (
                    <OneCashRequest key={i} data={item}/>
                  )
                }
              })
              :
              <h6
                className=""
                style={{
                  fontSize: "14px",
                }}
              >
                No request was found
              </h6>
            }
          </div>
        </div>
  
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }
}