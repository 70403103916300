import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { callApi, formatMoney, getInlineLoader } from "../Helpers";
import { PiStudent } from "react-icons/pi";
import { MdAddCircleOutline, MdEdit, MdSubject } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { formatDate } from "../Helpers";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ ready, setReady ] = useState(false);
  const [ individualPerformance, setIndividualPerformance ] = useState(0);
  const [ companyPerformance, setCompanyPerformance ] = useState(0);

  async function getTaskData() {
    await callApi(
      "get_task_data.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setData(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function init() {
    //run initializations here
    setReady(false);
    appContext.getAllStaff();
    await getTaskData();
    setReady(true);
  }

  function calculateCompanyPerformance() {
    let _performance = 0;

    if(data) {
      if(data.agentsList) {
        const _agentsList = JSON.parse(data.agentsList);
        let _totalAgents = _agentsList.length;
        let _totalCompleted = 0;

        for (const _agent of _agentsList) {
          if(_agent.completed) {
            ++_totalCompleted;
          }
        }

        if(_totalCompleted > 0) {
          _performance = ((_totalCompleted/_totalAgents) * 100).toFixed(1);
        }
      }
    }

    return _performance;
  }

  function calculateIndividualPerformance() {
    let _performance = 0;

    if(data) {
      if(data.agentsList) {
        const _agentsList = JSON.parse(data.agentsList);
        let _totalAgents = _agentsList.length;
        let _totalCompleted = 0;

        for (const _agent of _agentsList) {
          if(_agent.completed) {
            if(Number(_agent.completedBy) === Number(appContext.currentUserData.id)) {
              ++_totalCompleted;
            }
          }
        }

        if(_totalCompleted > 0) {
          _performance = ((_totalCompleted/_totalAgents) * 100).toFixed(1);
        }
      }
    }

    return _performance;
  }

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    if(data) {
      setIndividualPerformance(calculateIndividualPerformance());
      setCompanyPerformance(calculateCompanyPerformance());
    }
  }, [ data ])
  
  if(ready && data) {
    return (
      <div 
        className="container"
      >
        <div className="row">

          <div className="col-md-12">
            <h6 className="main-section-title font-semi-bold text-primary">
              {data.title} <br/>
              {
                (data.taskType === 'float')?
                <>
                  <span className="mb-2 mt-2 font-light" style={{ display: "block" }}>
                    {formatMoney(data.floatTarget)} Tsh
                  </span>
                  <span className="mb-2 mt-2 font-regular" style={{ display: "block", fontSize:"22px" }}>
                    {data.providerData?.name}
                  </span>
                </>
                :""
              }
              <span 
                className={
                  (data.status === 'pending') ?
                  "category-badge-lg bg-primary":
                  (data.status === 'completed') ?
                  "category-badge-lg bg-success text-light" :
                  (data.status === 'expired') ?
                  "category-badge-lg bg-danger text-light" : "category-badge-lg bg-secondary"
                }
              >
                {data.status}
              </span>
            </h6>

            <p
              style={{
                fontSize: "18px",
              }}
              className=""
            >
              From <i>{formatDate(data.startDate).substring(0, 10)}</i> 
              <br/>
              To <i>{formatDate(data.endDate).substring(0, 10)}</i>
            </p>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div
                  className="row text-center"
                  style={{
                    flexShrink: "0",
                    padding: "0px",
                  }}
                >
                  <div className="col-12">
                    <h6 style={{ fontSize: "12px", textTransform: "uppercase" }} className="font-orbitron-bold">Performance</h6>
                    <hr/>
                  </div>

                  <div className="col-6">
                    <h6
                      style={{
                        fontSize: "24px"
                      }}
                      className="font-bold"
                    >
                      {individualPerformance}%
                    </h6>
                    <h6 style={{ fontSize: "12px" }} className="font-semi-bold">You</h6>
                  </div>

                  <div className="col-6">
                    <h6
                      style={{
                        fontSize: "24px"
                      }}
                      className="font-bold"
                    >
                      {companyPerformance}%
                    </h6>
                    <h6 style={{ fontSize: "12px" }} className="font-semi-bold">All</h6>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div
                  className="row"
                  style={{
                    flexShrink: "0",
                    padding: "0px",
                  }}
                >
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <h6 
                        style={{ 
                          fontSize: "12px", 
                          textTransform: "uppercase",
                        }} 
                        className="font-orbitron-bold align-self-center mNoMargin"
                      >
                        Targeted Agents
                      </h6>

                      {
                        (appContext.doesCurrentUserOwnTask(id) && (data.status === 'pending' || data.status === 'expired') && data.taskType !== 'float') ?
                        <button
                          className="btn btn-secondary btn-sm align-self-center"
                          onClick={() => {
                            appContext.navTo({
                              item: 'view',
                              subItem: 'report-task',
                              moreItem: id,
                            })
                          }}
                        >
                          Give Report
                        </button>
                        :""
                      }
                    </div>
                    <hr/>
                  </div>

                  <div className="col-12">
                    <table
                      className="table table-striped"
                      style={{
                        fontSize: "14px"
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Agent
                          </th>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Attended
                          </th>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Attended By
                          </th>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Report
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          (data && data.agentsList && JSON.parse(data.agentsList).length > 0) ?
                          JSON.parse(data.agentsList).map((item, i) => {
                            return (
                              <tr
                                key={i}
                              >
                                <td
                                >
                                  <span
                                    className="font-bold text-primary"
                                    onClick={() => {
                                      appContext.navTo({
                                        item: 'side-view',
                                        subItem: 'staff-options',
                                        extraItem: Number(item.id)
                                      })
                                    }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {item.fullname}
                                  </span>
                                </td>
                                <td
                                >
                                  {
                                    (item.completed) ? "Yes" : "No"
                                  }
                                </td>
                                <td
                                >
                                  {
                                    (item.completedBy) ?
                                    <span
                                      className="font-bold text-dark"
                                      onClick={() => {
                                        appContext.navTo({
                                          item: 'side-view',
                                          subItem: 'staff-options',
                                          extraItem: Number(item.completedBy)
                                        })
                                      }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      {appContext.getStaffName(item.completedBy)}
                                    </span>
                                    :
                                    "N/A"
                                  }
                                </td>
                                <td
                                >
                                  {
                                    (item.completed) ?
                                    <button
                                      className="btn btn-sm btn-outline-dark"
                                      onClick={() => {
                                        appContext.navTo({
                                          item: 'view',
                                          subItem: 'task-report',
                                          extraItem: data.id,
                                          moreItem: item.id,
                                        })
                                      }}
                                      
                                    >
                                      View
                                    </button>
                                    :
                                    "N/A"
                                  }
                                </td>
                              </tr>
                            )
                          })
                          :null
                        }
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}