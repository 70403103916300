import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { callApi, formatMoney, getInlineLoader } from "../Helpers";
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { MdAddCircleOutline, MdCheckCircle, MdEdit, MdPerson, MdSubject } from "react-icons/md";
import { BiEdit, BiSend } from "react-icons/bi";
import { formatDate } from "../Helpers";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  async function getCashRequestData() {
    await callApi(
      "get_cash_request_data.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setData(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function init() {
    //run initializations here
    setReady(false);
    await getCashRequestData();
    setReady(true);
  }

  async function confirmRequest() {
    setLoading(true);
    await callApi(
      'confirm_cash_request.php',
      {
        cashRequestId: data.id,
      }
    ).then((response) => {
      if(response.status === 1) {
        appContext.tellMessage("Request was confirmed successfully")
        getCashRequestData();
        appContext.getAllAgentsCashRequests();
        appContext.getAllTpsfCashRequests();
        appContext.getBranchTpsfCashRequests();
        appContext.getMyCashRequests();
        
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  function viewRequester() {
    appContext.navTo({
      item: 'side-view',
      subItem: 'staff-options',
      extraItem: data.userId,
    })
  }

  function viewBranch() {
    appContext.navTo({
      item: 'side-view',
      subItem: 'branch-options',
      extraItem: data.branchId,
    })
  }

  async function forwardRequest() {
    setLoading(true);
    await callApi(
      'forward_cash_request.php',
      {
        cashRequestId: data.id,
      }
    ).then((response) => {
      if(response.status === 1) {
        appContext.tellMessage("Request was forwarded successfully")
        getCashRequestData();
        appContext.getBranchAgentsCashRequests();
        appContext.getAllTpsfCashRequests();
        appContext.getMyCashRequests();
        
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])
  
  if(ready && data) {
    return (
      <div 
        className="container"
      >
        <div className="row">

          <div className="col-md-12">
            <h6 className="main-section-title font-semi-bold text-primary">
              {formatMoney(data.amount)} Tsh <br/>
            </h6>

            <h6
              className="font-regular text-primary mb-4"
              style={{
                fontSize: "22px",
                wordWrap: "break-word", // Break long words to wrap them
              }}
            >
              CASH - {data.providerData?.name}
            </h6>

            <h6>
              <span 
                className={
                  (data.status === 'pending') ?
                  "category-badge-sm bg-primary":
                  (data.status === 'confirmed') ?
                  "category-badge-sm bg-success text-light" : "category-badge-sm bg-secondary"
                }
              >
                {data.status}
              </span>
            </h6>

            <p
              style={{
                fontSize: "14px",
              }}
              className=""
            >
              Requested On <i>{formatDate(data.requestDate).substring(0, 10)}</i> 
            </p>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  
                  {
                    (
                      data.status === 'pending' &&
                      (
                        (
                          (
                            appContext.currentUserData.role === 'admin' || 
                            appContext.currentUserData.role === 'cashier' || 
                            appContext.currentUserData.role === 'manager'
                          ) && data.requestAs === 'tpsf'
                        ) 
                        ||
                        (
                          (
                            appContext.currentUserData.role === 'tpsf' 
                          ) && data.requestAs === 'agent'
                        ) 
                      ) 
                      
                    ) ?
                    <div className="col-4 col-md-4">
                      <button
                        className="btn btn-sm"
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          appContext.activateDialog({
                            message: "Confirm that this request has been processed?",
                            onConfirm: confirmRequest,
                          })
                        }}
                      >
                        <MdCheckCircle size={40}/><br/>
                        Confirm
                      </button>
                      
                    </div>
                    :""
                  }

                  {
                    (
                      appContext.currentUserData.role === 'tpsf' &&
                      data.requestAs === 'agent' &&
                      data.status === 'pending' &&
                      data.isForwarded === 'no'
                    ) ?
                    <div className="col-4 col-md-4">
                      <button
                        className="btn btn-sm"
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          forwardRequest()
                        }}
                      >
                        <BiSend size={40}/><br/>
                        Forward
                      </button>
                    </div>
                    :""
                  }

                  <div className="col-4 col-md-4">
                    <button
                      className="btn btn-sm"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => {
                        viewRequester()
                      }}
                    >
                      <MdPerson size={40}/><br/>
                      View {data.requestAs}
                    </button>
                    
                  </div>

                  <div className="col-4 col-md-4">
                    <button
                      className="btn btn-sm"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => {
                        viewBranch()
                      }}
                    >
                      <PiBuildingOfficeBold size={40}/><br/>
                      View Branch
                    </button>
                  </div>

                  
                  
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}