import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { callApi, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import { PiStudent } from "react-icons/pi";
import { MdAddCircleOutline, MdEdit, MdSubject } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import OneFloatGoal from "../ones/OneFloatGoal";
import OneSupportedProvider from "../ones/OneSupportedProvider";
import { MdAdd, MdCheckCircle, MdCheckCircleOutline, MdOutlineDelete } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState();

  //..
  const [ name, setName ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ location, setLocation ] = useState(null);
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  const [ allRegions, setAllRegions ] = useState(null);
  const [ districts, setDistricts ] = useState(null);
  const [ wards, setWards ] = useState(null);

  //..

  async function getBranchData() {
    await callApi(
      "get_branch_data.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setData(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  
  function handleLocationChange(field, value) {
    if(value) {
      setLocation(_prev => {
        const _new = { ..._prev };
        _new[field] = value;
        
        if(field === 'region') {
          
          setDistricts(null);
          setWards(null);
          _new.district = null;
          _new.ward = null;
  
          //get all districts here
          (
            async () => {
              setLoading(true);
              await appContext.getDistricts({ regionId: value.id }).then(_districts => {
                setDistricts(_districts);
              })
              setLoading(false);
            }
          )();
        } else if(field === 'district') {
  
          setWards(null);
          _new.ward = null;
  
          //get all wards here
          (
            async () => {
              setLoading(true);
              await appContext.getWards({ districtId: value.id }).then(_wards => {
                setWards(_wards);
              })
              setLoading(false);
            }
          )();
        }
  
        return _new;
      })
    }
  }

  async function update() {
    if(!loading) {
      if(name.trim().length > 0) {
        if(location) {
          if(location.region) {
            if(location.district) {
              
              setLoading(true);
              await callApi(
                "update_branch_details.php", 
                { 
                  id,
                  name, 
                  location: JSON.stringify(location),
                  description, 
                }
              ).then(response => {
                if(response.status === 1) {
                  //successful
                  setOpSuccessful(true);
                  appContext.getAllBranches();
                  appContext.tellMessage("Successful")
                } else {
                  appContext.tellError(response.msg)
                }
              })
              setLoading(false);
              
            } else {
              appContext.tellError("Invalid district");
            }
          } else {
            appContext.tellError("Invalid region");
          }
        } else {
          appContext.tellError("Invalid branch location")
        }
      } else {
        appContext.tellError("Invalid Branch's name");
      }
    } else {
      appContext.tellInfo("Loading, please wait....")
    }
  }

  async function init() {
    //run initializations here
    setReady(false);
    await getBranchData();
    await appContext.getAllRegions().then((_regions) => {
      setAllRegions(_regions);
    });
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    if(data) {
      setName(data.name);
      setDescription(data.description);

      try {

        const _loc = JSON.parse(data.location);
        const _locFields = ['region', 'district', 'ward'];

        for (const _field of _locFields) {
          if(_loc[_field]) {
            handleLocationChange(_field, _loc[_field]);
          }
          
        }
      } catch (error) {
        //parse failed
      }
    }
  }, [ data ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])
  
  if(ready && data) {
    if(
      appContext.currentUserData.role === 'admin' || 
      appContext.currentUserData.role === 'manager'
    ) {
      //..
      return (
        <div 
          className="container"
          
        >
          <div className="mFormType1">
            
            <h6 className="section-title">Update Branch Details</h6>
          
            <div className="body">
              <div className="row">
                {
                  (opSuccessful) ?
                  <div className="col-md-12">
                    <div
                      className="bg-background text-center"
                      style={{
                        padding: "20px",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <h6
                        className="font-semi-bold text-success"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <MdCheckCircle size={60}/>
                        <br/>
                        Branch details were updated successfully
                      </h6>
                    </div>
                  </div>
                  :""
                }
    
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label">Branch's Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setOpSuccessful(false);
                      }}
                    />
                    <div className="form-text">Name to identify this branch</div>
                  </div>

                  <div className="mb-3">
                    <label>Branch's Location</label>

                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <label className="form-label font-regular">Region*</label>
                        <select 
                          className="form-control"
                          onChange={(e) => {
                            handleLocationChange('region', ((e.target.value) ? JSON.parse(e.target.value) : ""))
                          }}
                          value={(location) ? JSON.stringify(location.region) : ''}
                        >
                          <option value=""></option>
                          {
                            (allRegions && allRegions.length > 0) ?
                            allRegions.map((item, i) => {
                              return (
                                <option
                                  value={JSON.stringify({ name: item.name, id: item.id })}
                                  key={i}
                                >
                                  {item.name}
                                </option>
                              )
                            }): ""
                          }
                        </select>
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="form-label font-regular">District*</label>
                        <select 
                          className="form-control"
                          onChange={(e) => {
                            handleLocationChange('district', ((e.target.value) ? JSON.parse(e.target.value) : ""))
                          }}
                          value={(location) ? JSON.stringify(location.district) : ''}
                        >
                          <option value=""></option>
                          {
                            (districts && districts.length > 0) ?
                            districts.map((item, i) => {
                              return (
                                <option
                                  value={JSON.stringify({ name: item.name, id: item.id })}
                                  key={i}
                                >
                                  {item.name}
                                </option>
                              )
                            }): ""
                          }
                        </select>
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="form-label font-regular">Ward or Village</label>
                        <select 
                          className="form-control"
                          onChange={(e) => {
                            handleLocationChange('ward', ((e.target.value) ? JSON.parse(e.target.value) : ""))
                          }}
                          value={(location) ? JSON.stringify(location.ward) : ''}
                        >
                          <option value=""></option>
                          {
                            (wards && wards.length > 0) ?
                            wards.map((item, i) => {
                              return (
                                <option
                                  value={JSON.stringify({ name: item.name, id: item.id })}
                                  key={i}
                                >
                                  {item.name}
                                </option>
                              )
                            }): ""
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Branch's Description</label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        setOpSuccessful(false);
                      }}
                      rows={4}
                    ></textarea>
                    <div className="form-text">Optional description for this branch</div>
                  </div>

                </div>
    
                <div className="col-md-12 text-end">
                  <button
                    className="btn btn-primary btn-padded mSupportLoading"
                    onClick={() => {
                      appContext.activateDialog({
                        message: `Branch ${String(name).toUpperCase()} will be updated, continue?`,
                        onConfirm: update,
                      })
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
    
          </div>
          
    
        </div>
      )
      //..
    } else {
      //no permission
    }
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}