import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import CashManagementHome from "../views/CashManagementHome";
import AdminCashManagementHome from "../views/AdminCashManagementHome";
import ManagerCashManagementHome from "../views/ManagerCashManagementHome";
import AgentsCashRequests from "../views/AgentsCashRequests";

export default function CashManagement(props) {

  const appContext = useContext(AppContext);

  const [ view, setView ] = useState(null);

  async function init() {
    //run initializations here
  }

  function getView() {
    if(
      !appContext.navSubItem &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      if(appContext.currentUserData.role === 'admin' || appContext.currentUserData.role === 'cashier') {
        return <AdminCashManagementHome/>
      } else if(appContext.currentUserData.role === 'manager') {
        return <ManagerCashManagementHome/>
      } else {
        return <CashManagementHome/>
      }
    } else if(
      appContext.navSubItem === 'agents_cash_requests' &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      return <AgentsCashRequests/>
    }

    return null;
  }

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    const _view = getView();
    if(_view) {
      setView(_view);
    }
  }, [ appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem ])

  //return desired view
  return view;
}