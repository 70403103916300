import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { MdChevronRight } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ data, setData ] = useState(props.data);

  async function init() {
    //run initializations here
  }

  useEffect(() => {
    init();
  }, [ data ])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ])
  
  return (
    <div 
      className="One"
      onClick={() => {
        appContext.navTo({
          item: "side-view",
          subItem: "staff-options",
          extraItem: data.id,
        })
      }}
    >
      
      <div 
        className="d-flex justify-content-between"
        style={{
          maxWidth: "100%",
          width: "100%",
          flexWrap: "nowrap", // Ensure the container doesn't wrap its children
        }}
      >
        <div
          className="align-self-center"
          style={{
            flexShrink: "1", // Allow the flex item to shrink
            flexGrow: "1",
            minWidth: "0", // Ensure the item can shrink below its content width
          }}
        >
          <h6
            className="font-bold text-primary mNoMargin"
            style={{
              fontSize: "14px",
              wordWrap: "break-word", // Break long words to wrap them
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal" // Allow text to wrap to the next line
            }}
          >
            {data.fullname}
          </h6>

          <p
            className="text-muted mNoMargin"
            style={{
              fontSize: "14px",
              wordWrap: "break-word", // Break long words to wrap them
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal" // Allow text to wrap to the next line
            }}
          >
            Phone: <i>{data.phone}</i> <br/>
            <span className="category-badge-sm bg-secondary">{data.role}</span>
          </p>
        </div>

        
      </div>

    </div>
  )
}