import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import OneCashRequest from '../ones/OneCashRequest';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const[ ready, setReady ] = useState(false);

 async function init() {
    //run initializations here
    setReady(false);
    await appContext.getTotalIncome();
    await appContext.getTotalExpenses();
    setReady(true);
  }


  useEffect(() => {
    init();
  }, [ ])

  
  
  if(ready) {
    return (
      <div 
        className="container"
      >
        <div 
          className="card"
        >
          
          <div className="card-body">
            
            <h1 className="main-section-title">Accounting</h1>

            <div className="row" style={{ marginBottom: "20px" }}>
              <div className="col-md-6 col-12 mb-4">
                <div 
                  className=""
                  style={{
                    width:"100%",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "2px solid var(--secondaryColor)"
                  }}
                >
                  <h5
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    {formatMoneyShorten(appContext.totalIncome)}
                  </h5>
                  <h6 style={{ fontSize: "12px" }}>TOTAL INCOME (TSH)</h6>

                  <div className="text-end mt-4">
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        appContext.navTo({
                          item: 'accounting',
                          subItem: 'income'
                        })
                      }}
                      style={{
                        marginRight: "10px"
                      }}
                    >
                      View All
                    </button>

                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        appContext.navTo({
                          item: 'view',
                          subItem: 'record-income'
                        })
                      }}
                    >
                      Record
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 mb-4">
                <div 
                  className=""
                  style={{
                    width:"100%",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "2px solid var(--secondaryColor)"
                  }}
                >
                  <h5
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    {formatMoneyShorten(appContext.totalExpenses)}
                  </h5>
                  <h6 style={{ fontSize: "12px" }}>TOTAL EXPENSES (TSH)</h6>

                  <div className="text-end mt-4">

                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        appContext.navTo({
                          item: 'accounting',
                          subItem: 'expenses'
                        })
                      }}
                      style={{
                        marginRight: "10px"
                      }}
                    >
                      View All
                    </button>

                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        appContext.navTo({
                          item: 'view',
                          subItem: 'record-expense'
                        })
                      }}
                    >
                      Record
                    </button>
                  </div>

                </div>
              </div>

              <div className="col-12 col-md-12 mb-4 text-center">
                <div 
                  className=""
                  style={{
                    width:"100%",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "2px solid var(--secondaryColor)"
                  }}
                >
                  <h5
                    style={{
                      fontSize: "32px",
                    }}
                    className="font-semi-bold"
                  >
                    {formatMoneyShorten(Number(appContext.totalIncome) - Number(appContext.totalExpenses))} 
                    
                  </h5>
                  <h6 style={{ fontSize: "12px" }}>NET PROFIT/LOSS IN TSH</h6>

                  

                </div>
              </div>

            </div>        
      
          </div>
        </div>
  
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }
}