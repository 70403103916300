import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle, MdWarning } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ amount, setAmount ] = useState(0);
  const [ providerId, setProviderId ] = useState("");
  
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getSupportedProviders();
    setReady(true);
  }

  function resetFields() {
    setAmount(0);
    setProviderId("");
    setOpSuccessful(false);
  }

  async function sendRequest() {
    if(!loading) {
      if(!isNaN(amount) && Number(amount) > 0) {
        if(String(providerId).trim().length > 0) {
          setLoading(true);
          await callApi(
            'send_cash_request.php',
            {
              amount,
              providerId,
              requestAs: appContext.currentUserData.role,
              branchId: appContext.currentUserData.branchId,
            }
          ).then((response) => {
            if(response.status === 1) {
              setOpSuccessful(true);
              appContext.getMyCashRequests();
              appContext.tellMessage("Request sent!");
            } else {
              appContext.tellError(response.msg);
            }
          });
          setLoading(false);
        } else {
          appContext.tellError("Invalid provider selection");
        }
      } else {
        appContext.tellError("Invalid amount");
      }
    } else {
      appContext.tellWarning("Loading, Please wait....")
    }
  }

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  if(ready) {
    if(appContext.currentUserData.role === 'agent' || appContext.currentUserData.role === 'tpsf') {
      return (
        <div 
          className="container"
          
        >
          <div className="mFormType1">
            
            <h6 className="section-title">Request Cash</h6>
          
            <div className="body">
              <div className="row">
                {
                  (opSuccessful) ?
                  <div className="col-md-12">
                    <div
                      className="bg-background text-center"
                      style={{
                        padding: "20px",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <h6
                        className="font-semi-bold text-success"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <MdCheckCircle size={60}/>
                        <br/>
                        Request was sent successfully

                        <hr/>

                        <button
                          className="btn btn-padded btn-secondary"
                          onClick={resetFields}
                        >
                          Request Again
                        </button>
                      </h6>
                    </div>
                  </div>
                  :
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="">Requesting As</label>
                      <h6
                        className="font-regular"
                        style={{
                          fontSize: "24px",
                          textTransform: "capitalize",
                        }}
                      >
                        {appContext.currentUserData.role}
                      </h6>
                    </div>

                    {
                      (appContext.currentUserData.role === 'tpsf' || appContext.currentUserData.role === 'agent') ?
                      <div className="mb-3">
                        <label className="">Branch</label>
                        <h6
                          className="font-regular"
                          style={{
                            fontSize: "24px",
                            textTransform: "capitalize",
                          }}
                        >
                          {appContext.currentUserData.branchData.name}
                        </h6>
                      </div>
                      :""
                    }

                    <div className="mb-3">
                      <label className="">Select Provider</label>
                      <select
                        className="form-control"
                        value={providerId}
                        onChange={(e) => setProviderId(e.target.value)}
                      >
                        <option value=""></option>
                        {
                          (appContext.supportedProviders && appContext.supportedProviders.length > 0) ?
                          appContext.supportedProviders.map((item, i) => {
                            return (
                              <option
                                key={i}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            )
                          }):""
                        }
                      </select>
                    </div>

                    <div className="mb-3">
                      <label>Amount</label>
                      <input
                        className="form-control"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>

                    <div className="mb-3 text-end">
                      <button
                        className="btn btn-padded mSupportLoading btn-primary"
                        onClick={() => {
                          appContext.activateDialog({
                            message: "Are you sure you want to send cash request?",
                            onConfirm: sendRequest,
                          })
                        }}
                      >
                        {(loading) ? getInlineLoader() : "Send Request"}
                      </button>
                    </div>
                    
                  </div>
                }
    
                

                
              </div>
            </div>
    
          </div>
          
    
        </div>
      )
    } else {
      return (
        <div 
          className="container"
          
        >
          <div className="mFormType1">
            
            <h6 className="section-title">Request Cash</h6>
          
            <div className="body">
              <div className="row">
                
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div
                      className="bg-background text-center"
                      style={{
                        padding: "20px",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <h6
                        className="font-semi-bold text-danger"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <MdWarning size={60}/>
                        <br/>
                        Only TPSFs and Agents can make cash requests
                      </h6>
                    </div>
                  </div>
                </div>

              </div>
            </div>
    
          </div>
          
    
        </div>
      )
    }
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}