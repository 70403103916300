import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle } from "react-icons/md";

export default function View({ staffId }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ staffData, setStaffData ] = useState(null);
  const [ cashBook, setCashBook ] = useState(null);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  async function init() {
    //run initializations here if any
    setReady(false);
    await getStaffData();
    await getCashBook();
    setReady(true);
  }


  async function getStaffData() {
    await callApi('get_staff_data.php', { id: staffId }).then(response => {
      if(response.status === 1) {
        setStaffData(response.data);
      }
    })
  }

  async function getCashBook() {
    await callApi('get_staff_cash_book.php', { id: staffId, }).then(response => {
      if(response.status === 1) {
        setCashBook(response.data);
      }
    })
  }
  
  
  useEffect(() => {
    init();
  }, [ ])
  
  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  if(ready && staffData && cashBook) {
    return (
      <div 
        className="container"
        
      >
        <div className="mFormType1" style={{ maxWidth: "900px" }}>
          
          <h6 className="section-title">Staff Cash Book</h6>
        
          <div className="body">
            <div className="row">
              

              <div className="col-md-6 mb-4">
                <label className="form-label">Name of the Staff</label>
                <h3
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {staffData.fullname}
                  <span className="font-bold text-primary" style={{ display: "block", fontSize: "16px" }}>{staffData.role}</span>
                </h3>
              </div>

              <div className="col-12">
                <hr/>
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="font-semi-bold" style={{ fontSize: "18px" }}>DR</h6>
                    <table
                      className="table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th style={{ fontSize: "12px" }}>Date</th>
                          <th style={{ fontSize: "12px" }}>Title</th>
                          <th style={{ fontSize: "12px" }}>Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          (cashBook.dr && cashBook.dr.length > 0) ?
                          cashBook.dr.map((item, i) => {
                            return (
                              <tr
                                key={i}
                              >
                                <td>{formatDate(item.transactionDate).substring(0, 10)}</td>
                                <td>{item.title}</td>
                                <td>{formatMoney(item.amount)}</td>
                              </tr>
                            )
                          })
                          :"" 
                        }

                        {
                          (Number(cashBook.balanceDr) > 0) ?
                          <tr>
                            <td></td>
                            <td className="font-semi-bold">Balance B/D</td>
                            <td className="font-semi-bold">
                              {formatMoney(cashBook.balanceDr)}
                            </td>
                          </tr>
                          :""
                        }

                        <tr>
                          <td></td>
                          <td className="font-semi-bold">Sub Total</td>
                          <td className="font-semi-bold">
                            {formatMoney(cashBook.subTotal)}
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                  <div className="col-md-6">
                    <h6 className="font-semi-bold" style={{ fontSize: "18px" }}>CR</h6>
                    <table
                      className="table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th style={{ fontSize: "12px" }}>Date</th>
                          <th style={{ fontSize: "12px" }}>Title</th>
                          <th style={{ fontSize: "12px" }}>Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                        (cashBook.cr && cashBook.cr.length > 0) ?
                        cashBook.cr.map((item, i) => {
                          return (
                            <tr
                              key={i}
                            >
                              <td>{formatDate(item.transactionDate).substring(0, 10)}</td>
                              <td>{item.title}</td>
                              <td>{formatMoney(item.amount)}</td>
                            </tr>
                          )
                        })
                        :""
                        }

                        {
                          (Number(cashBook.balanceCr) > 0) ?
                          <tr>
                            <td></td>
                            <td className="font-semi-bold">Balance C/D</td>
                            <td className="font-semi-bold">
                              {formatMoney(cashBook.balanceCr)}
                            </td>
                          </tr>
                          :""
                        }

                        <tr>
                          <td></td>
                          <td className="font-semi-bold">Sub Total</td>
                          <td className="font-semi-bold">
                            {formatMoney(cashBook.subTotal)}
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
                <hr/>
              </div>
              
            </div>
          </div>
  
        </div>
        
  
      </div>
    )
  } else {
    return (
      <div 
        className="container"
        
      >
        <div className="mFormType1" style={{ maxWidth: "900px" }}>
          
          <h6 className="section-title">Staff Cash Book</h6>
        
          <div className="body">
            <div style={{ width: "100%" }} className="mSupportLoading">
              {getInlineLoader()}
            </div>
          </div>
  
        </div>
        
  
      </div>
    )
  }
}