import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import AccountingHome from "../views/AccountingHome";
import Income from "../views/Income";
import Expenses from "../views/Expenses";

export default function Accounting(props) {

  const appContext = useContext(AppContext);

  const [ view, setView ] = useState(null);

  async function init() {
    //run initializations here
  }

  function getView() {
    if(
      !appContext.navSubItem &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      return <AccountingHome/>
    } else if(
      appContext.navSubItem === 'income' &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      return <Income/>
    } else if(
      appContext.navSubItem === 'expenses' &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      return <Expenses/>
    }

    return null;
  }

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    const _view = getView();
    if(_view) {
      setView(_view);
    }
  }, [ appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem ])

  //return desired view
  return view;
}