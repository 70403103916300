import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { callApi, getInlineLoader } from "../Helpers";
import { PiStudent } from "react-icons/pi";
import { MdAddCircleOutline, MdEdit, MdSubject } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import OneStaff from '../ones/OneStaff';
import OneFloatGoal from '../ones/OneFloatGoal';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ branchTpsfs, setBranchTpsfs ] = useState(null);
  const [ branchAgents, setBranchAgents ] = useState(null);
  const [ branchFloatGoals, setBranchFloatGoals ] = useState(null);
  const [ ready, setReady ] = useState(false);

  async function getBranchData() {
    await callApi(
      "get_branch_data.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setData(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function getBranchTpsfs() {
    await callApi(
      "get_branch_tpsfs.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setBranchTpsfs(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function getBranchAgents() {
    await callApi(
      "get_branch_agents.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setBranchAgents(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function getBranchFloatGoals() {
    await callApi(
      "get_branch_float_goals.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setBranchFloatGoals(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function init() {
    //run initializations here
    setReady(false);
    await getBranchData();
    await getBranchFloatGoals();
    await getBranchTpsfs();
    await getBranchAgents();
    await appContext.getSupportedProviders();
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])
  
  if(ready && data) {
    if(
      appContext.currentUserData.role === 'admin' ||
      appContext.currentUserData.role === 'manager'
    ) {
      return (
        <div 
          className="container"
        >
          <div className="row">
  
            <div className="col-md-12">
              <h6 className="main-section-title font-semi-bold text-primary">
                {data.name} <br/>
                <span className="category-badge-lg bg-secondary">Branch</span>
              </h6>
              <p
                style={{
                  fontSize: "18px",
                }}
                className=""
              >
                {data.description}
              </p>
            </div>
  
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  
                  <div className="row">
                    <div className="col-6 col-md-6 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className="font-semi-bold text-primary"
                      >
                        {JSON.parse(data.location)?.region?.name || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Region</h6>
                    </div>
  
                    <div className="col-6 col-md-6 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className="font-semi-bold text-primary"
                      >
                        {JSON.parse(data.location)?.district?.name || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>District</h6>
                    </div>
  
                    <div className="col-6 col-md-6 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className="font-semi-bold text-primary"
                      >
                        {JSON.parse(data.location)?.ward?.name || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Ward/Village</h6>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
  
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  
                  <div className="row">
                    <div className="col-12">
                      
                      <div className="d-flex justify-content-between">
                        <h4
                          className="font-semi-bold align-self-center mNoMargin"
                          style={{
                            fontSize: "18px"
                          }}
                        >
                          Books
                        </h4>
  
                        
                      </div>
                      <hr/>
                    </div>
  
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-4 col-6 mb-2">
                          <button
                            className="btn btn-block btn-outline-dark btn-block"
                            onClick={() => {
                              appContext.navTo({
                                item: 'view',
                                subItem: 'branch-cash-book',
                                extraItem: data.id,
                              })
                            }}
                            style={{
                              height: "100%"
                            }}
                          >
                            Cash Book
                          </button>
                        </div>
                        {
                          (appContext.supportedProviders && appContext.supportedProviders.length > 0) ?
                          appContext.supportedProviders.map((item, i) => {
                            return (
                              <div className="col-md-4 col-6 mb-2" key={i}>
                                <button
                                  className="btn btn-block btn-outline-dark btn-block"
                                  onClick={() => {
                                    appContext.navTo({
                                      item: 'view',
                                      subItem: 'branch-float-book',
                                      extraItem: data.id,
                                      moreItem: item.id,
                                    })
                                  }}
                                  style={{
                                    height: "100%"
                                  }}
                                >
                                  {item.name}
                                </button>
                              </div>
                            )
                          })
                          :
                          ""
                        }
                      </div>
                    </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>
  
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  
                  <div className="row">
                    <div className="col-12">
                      
                      <div className="d-flex justify-content-between">
                        <h4
                          className="font-semi-bold align-self-center mNoMargin"
                          style={{
                            fontSize: "18px"
                          }}
                        >
                          TPSFs ({(branchTpsfs) ? branchAgents.length : 0 })
                        </h4>
  
                        <button
                          className="btn btn-sm btn-secondary align-self-center"
                          onClick={() => {
                            appContext.navTo({
                              item: 'view',
                              subItem: 'register-staff',
                              extraItem: id,
                              moreItem: "tpsf",
                            })
                          }}
                        >
                          Register
                        </button>
                      </div>
                      <hr/>
                    </div>
  
                    <div className="col-12">
                      {
                        (branchTpsfs && branchTpsfs.length > 0) ?
                        <>
                          {
                            branchTpsfs.slice(0, 2).map((item, i) => {
                              return (
                                <OneStaff key={i} data={item} />
                              )
                            })
                          }
  
                          <div className="text-start mt-4">
                            <h6
                              className="font-bold text-primary"
                              style={{
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                appContext.navTo({
                                  item: 'staff',
                                  subItem: 'tpsf',
                                  extraItem: id,
                                })
                              }}
                            >
                              View All TPSFs...
                            </h6>
                          </div>
                        </>
                        :
                        <h6>
                          No TPSF was found
                        </h6>
                      }
                    </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>
  
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  
                  <div className="row">
                    <div className="col-12">
                      
                      <div className="d-flex justify-content-between">
                        <h4
                          className="font-semi-bold align-self-center mNoMargin"
                          style={{
                            fontSize: "18px"
                          }}
                        >
                          Agents ({(branchAgents) ? branchAgents.length : 0 })
                        </h4>
  
                        <button
                          className="btn btn-sm btn-secondary align-self-center"
                          onClick={() => {
                            appContext.navTo({
                              item: 'view',
                              subItem: 'register-staff',
                              extraItem: id,
                              moreItem: "agent",
                            })
                          }}
                        >
                          Register
                        </button>
                      </div>
                      <hr/>
                    </div>
  
                    <div className="col-12">
                      {
                        (branchAgents && branchAgents.length > 0) ?
                        <>
                          {
                            branchAgents.slice(0, 2).map((item, i) => {
                              return (
                                <OneStaff key={i} data={item} />
                              )
                            })
                          }
                          <div className="text-start mt-4">
                            <h6
                              className="font-bold text-primary"
                              style={{
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                appContext.navTo({
                                  item: 'staff',
                                  subItem: 'agent',
                                  extraItem: id,
                                })
                              }}
                            >
                              View All Agents...
                            </h6>
                          </div>
                        </>
                        :
                        <h6>
                          No Agent was found
                        </h6>
                      }
                    </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-12">
                          <h4
                            className="font-semi-bold align-self-center"
                            style={{
                              fontSize: "18px"
                            }}
                          >
                            Branch Settings
                          </h4>
                          <hr/>
                        </div>
  
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-4 col-6 mb-2">
                              <button
                                className="btn btn-block btn-outline-dark btn-block"
                                onClick={() => {
                                  appContext.navTo({
                                    item: 'view',
                                    subItem: 'update-branch-details',
                                    extraItem: data.id,
                                  })
                                }}
                                style={{
                                  height: "100%"
                                }}
                              >
                                Update Details 
                              </button>
                            </div>
                            

                          </div>
                        </div>
                        
                      </div>
                    </div>
  
                  </div>
                </div>
              </div>
            </div>
  
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  
                  <div className="row">
                    <div className="col-12">
                      
                      <div className="d-flex justify-content-between">
                        <h4
                          className="font-semi-bold align-self-center mNoMargin"
                          style={{
                            fontSize: "18px"
                          }}
                        >
                          Float Goals
                        </h4>
  
                        <button
                          className="btn btn-sm btn-secondary align-self-center"
                          onClick={() => {
                            appContext.navTo({
                              item: 'view',
                              subItem: 'create-branch-float-goal',
                              extraItem: id,
                            })
                          }}
                        >
                          Create
                        </button>
                      </div>
                      <hr/>
                    </div>
  
                    <div className="col-12">
                      {
                        (branchFloatGoals && branchFloatGoals.length > 0) ?
                        branchFloatGoals.map((item, i) => {
                          return (
                            <OneFloatGoal key={i} data={item} />
                          )
                        })
                        :
                        <h6>
                          No goal was found
                        </h6>
                      }
                    </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>
  
          </div>
        </div>
      )
    } else {
      return (
        <div 
          className="container"
        >
          <div className="row">
  
            <div className="col-md-12">
              <h6 className="main-section-title font-semi-bold text-primary">
                {data.name} <br/>
                <span className="category-badge-lg bg-secondary">Branch</span>
              </h6>
              <p
                style={{
                  fontSize: "18px",
                }}
                className=""
              >
                {data.description}
              </p>
            </div>
  
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  
                  <div className="row">
                    <div className="col-6 col-md-6 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className="font-semi-bold text-primary"
                      >
                        {JSON.parse(data.location)?.region?.name || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Region</h6>
                    </div>
  
                    <div className="col-6 col-md-6 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className="font-semi-bold text-primary"
                      >
                        {JSON.parse(data.location)?.district?.name || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>District</h6>
                    </div>
  
                    <div className="col-6 col-md-6 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className="font-semi-bold text-primary"
                      >
                        {JSON.parse(data.location)?.ward?.name || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Ward/Village</h6>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
  
            
          </div>
        </div>
      )
    }
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}