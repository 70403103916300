import { useContext, useState } from "react"
import { AppContext } from "../App"
import logo from '../assets/logo.png';
import logo_worded from '../assets/logo_worded.png';
import logo_worded_only from '../assets/logo_worded_only.png';
import { callApi, getInlineLoader, setCookie } from "../Helpers";
import { MdLock, MdLogin, MdPerson } from "react-icons/md";

export default function Login(props) {

  const appContext = useContext(AppContext);

  const [ loading, setLoading ] = useState(false);
  const [ phone, setPhone ] = useState("");
  const [ password, setPassword ] = useState("");

  async function login() {
    if(!loading) {
      if(phone.trim().length > 0) {
        if(password.trim().length > 0) {

          setLoading(true);
          await callApi("login.php", { phone, password }).then((response) => {
            if(response.status === 1) {
              setCookie('userId', response.data.userId);
              setCookie('userToken', response.data.userToken);

              appContext.tellMessage("Welcome....");
              appContext.navTo({
                item: 'dashboard',
              })
              appContext.refresh();

            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);

        } else {
          appContext.tellError("Invalid Password");
        }
      } else {
        appContext.tellError("Invalid Email or Phone");
      }
    } else {
      appContext.tellInfo("Signing in, Please wait....")
    }
  }

  return (
    <div 
      className="Login"
    >
      <div
        className="body bg-surface mShadow5"
      >
        <div className="container">
          <div className="row">

            <div className="col-md-6">
              <div
                style={{
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
                className="mDesktopOnly"
              >
                <img
                  src={logo}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  margin: "0 auto",
                  paddingBottom: "40px",
                }}
                className="mMobileOnly"
              >
                <img
                  src={logo}
                  style={{
                    width: "100%",
                    height: "auto",

                  }}
                />
              </div>

            </div>
            

            <div className="col-md-6">
              <div
                style={{
                  maxWidth: "300px",
                  margin: "0 auto",
                }}
              >
                <div 
                  className="mDesktopOnly"
                  style={{
                    height: "calc(var(--topBarHeight) * 1.0)",
                    width: "100%",
                  }}
                >
                </div>

                <div className="mb-4 text-start">
                  <h1 
                    style={{
                      fontSize: "34px",
                    }}
                    className="font-regular text-primary"
                  >
                    Balwyn Investment LTD
                  </h1>
                  <h6
                    className="font-bold"
                    style={{
                      fontSize: "14px"
                    }}
                  >
                    AGENTS MANAGEMENT SYSTEM
                  </h6>
                </div>

                <div
                  className="mb-3 input-group"
                >
                  <span className="input-group-text"><MdPerson className="text-primary" size={20}/></span>
                  <input
                    className="form-control"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    placeholder="Phone"
                    type="text"
                  />
                </div>

                <div
                  className="mb-3 input-group"
                >
                  <span className="input-group-text"><MdLock className="text-primary" size={20}/></span>
                  <input
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Password"
                    type="password"
                  />
                </div>

                
                <div
                  className="mb-4"
                >
                  <button
                    className="btn btn-primary btn-rounded btn-padded btn-block mSupportLoading font-orbitron-bold"
                    onClick={login}
                  >
                    {(loading) ? getInlineLoader() : <>Login <MdLogin className="mNoMargin mNoPadding" size={24}/></>}
                  </button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}