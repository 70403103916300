import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdAdd, MdCheck, MdCheckCircle, MdDelete, MdOutlineDelete } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ fullname, setFullname ] = useState("");
  const [ nida, setNida ] = useState("");
  const [ branchId, setBranchId ] = useState(props.branchId || "");
  const [ role, setRole ] = useState(props.role || "agent");
  const [ phone, setPhone ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ confirm, setConfirm ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ location, setLocation ] = useState(null);
  const [ supportedProviders, setSupportedProviders ] = useState([]);
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  const [ allRegions, setAllRegions ] = useState(null);
  const [ districts, setDistricts ] = useState(null);
  const [ wards, setWards ] = useState(null);

  const providerTemplate = {
    id: "",
    name: "",
    providerType: "",
    tillNumber: "",
    accountNumber: "",
    deviceType: "",
    deviceSerialNumber: "",
    deviceImei1: "",
    deviceImei2: "",
  }

  async function init() {
    //run initializations here
    setLoading(true);
    appContext.getAllBranches(); //get all branches in the background
    appContext.getSupportedProviders();
    await appContext.getAllRegions().then((_regions) => {
      setAllRegions(_regions);
    });
    setLoading(false);
    
  }

  function resetInputFields() {
    setFullname("");
    setPhone("");
    setPassword("");
    setConfirm("");
    setRole("agent");
    setLocation(null);
    setNida("");
    setSupportedProviders([]);
  }

  function handleProviderIdChange(index, value) {
    let providerData = null;

    if(appContext.supportedProviders) {
      for (const _prov of appContext.supportedProviders) {
        if(Number(_prov.id) === Number(value)) {
          providerData = _prov;
        }
      }

      if(providerData) {
        setSupportedProviders(_prev => {
          let _new = [ ..._prev ];
          _new[index] = { ...providerTemplate, ...providerData };

          return _new;
        })
      }
    }
  }

  function handleDeviceTypeChange(index, value) {
    setSupportedProviders(_prev => {
      let _new = [ ..._prev ];
      _new[index].deviceType = value;

      return _new;
    })
  }

  function handleAccountNumberChange(index, value) {
    setSupportedProviders(_prev => {
      let _new = [ ..._prev ];
      _new[index].accountNumber = value;

      return _new;
    })
  }

  function handleDeviceSerialNumberChange(index, value) {
    setSupportedProviders(_prev => {
      let _new = [ ..._prev ];
      _new[index].deviceSerialNumber = value;

      return _new;
    })
  }

  function handleDeviceImei1Change(index, value) {
    setSupportedProviders(_prev => {
      let _new = [ ..._prev ];
      _new[index].deviceImei1 = value;

      return _new;
    })
  }

  function handleDeviceImei2Change(index, value) {
    setSupportedProviders(_prev => {
      let _new = [ ..._prev ];
      _new[index].deviceImei2 = value;

      return _new;
    })
  }

  function handleTillNumberChange(index, value) {
    setSupportedProviders(_prev => {
      let _new = [ ..._prev ];
      _new[index].tillNumber = value;

      return _new;
    })
  }

  function addProvider() {
    setSupportedProviders(_prev => {
      let _new = [ ..._prev, providerTemplate ];

      return _new;
    })
  }

  function deleteProvider(index) {
    setSupportedProviders(_prev => {
      let _new = [ ..._prev ];
      _new.splice(index, 1);

      return _new;
    })
  }

  function register() {

    let _handler = async () => {
      //..
      setLoading(true);
      await callApi(
        "register_staff.php", 
        { 
          fullname, 
          phone,
          password,
          role, 
          branchId,
          location: JSON.stringify(location),
          nida,
          supportedProviders: JSON.stringify(supportedProviders),
        }
      ).then(response => {
        if(response.status === 1) {
          //successful
          resetInputFields();
          setOpSuccessful(true);
          appContext.getAllStaff();
          appContext.tellMessage("Successful");
        } else {
          appContext.tellError(response.msg)
        }
      })
      setLoading(false);
      //..
    }
    if(!loading) {
      if(fullname.trim().length > 0) {
        if(phone.trim().length === 10) {
          if(
            ((role === 'tpsf' || role === 'manager') && String(branchId).trim().length > 0) ||
            role !== 'tpsf'
          ) {
            if(
              (role === 'agent' && location && location.region && location.district) ||
              role !== 'agent'
            ) {
              //..
              if(role === 'agent') {
                //send without pwd checks
                _handler();
              } else {
                //check pwd
                //..
                if(password.trim().length >= 6) { //no pwd for agents
                  if(password === confirm) {
                    //check branch if it's tpsf
                    _handler();
            
                  } else {
                    appContext.tellError("Confirm password correctly");
                  }
                } else {
                  appContext.tellError("Password must have atleast 6 characters");
                }
                //..
              }
              //..
            } else {
              appContext.tellError("Invalid location for Agent");
            }
          } else {
            appContext.tellError("Invalid branch for TCPF");
          }
          
        } else {
          appContext.tellError("Invalid phone number");
        }
      } else {
        appContext.tellError("Invalid fullname");
      }
    } else {
      appContext.tellInfo("Loading, please wait....")
    }
  }

  function handleLocationChange(field, value) {
    if(value) {
      setLocation(_prev => {
        const _new = { ..._prev };
        _new[field] = value;
        
        if(field === 'region') {
          
          setDistricts(null);
          setWards(null);
          _new.district = null;
          _new.ward = null;
  
          //get all districts here
          (
            async () => {
              setLoading(true);
              await appContext.getDistricts({ regionId: value.id }).then(_districts => {
                setDistricts(_districts);
              })
              setLoading(false);
            }
          )();
        } else if(field === 'district') {
  
          setWards(null);
          _new.ward = null;
  
          //get all wards here
          (
            async () => {
              setLoading(true);
              await appContext.getWards({ districtId: value.id }).then(_wards => {
                setWards(_wards);
              })
              setLoading(false);
            }
          )();
        }
  
        return _new;
      })
    }
  }

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  return (
    <div 
      className="container"
      
    >
      <div className="mFormType1">
        
        <h6 className="section-title">Register Staff</h6>
      
        <div className="body">
          <div className="row">
            {
              (opSuccessful) ?
              <div className="col-md-12">
                <div
                  className="bg-background text-center"
                  style={{
                    padding: "20px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <h6
                    className="font-semi-bold text-success"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    <MdCheckCircle size={60}/>
                    <br/>
                    Staff was registered successfully
                  </h6>
                </div>
              </div>
              :""
            }

            <div className="mb-3 col-12">
              <label className="form-label">Fullname*</label>
              <input
                type="text"
                className="form-control"
                value={fullname}
                onChange={(e) => {
                  setFullname(e.target.value);
                  setOpSuccessful(false);
                }}
              />
              <div className="form-text">Staff's Fullname</div>
            </div>

            <div className="mb-3 col-12">
              <label className="form-label">NIDA Number</label>
              <input
                type="text"
                className="form-control"
                value={nida}
                onChange={(e) => {
                  setNida(e.target.value);
                  setOpSuccessful(false);
                }}
              />
            </div>

            <div className="mb-3 col-12">
              <label className="form-label">Phone Number*</label>
              <input
                type="text"
                className="form-control"
                placeholder="07XXXXXXXX or 06XXXXXXXX"
                value={phone}
                maxLength={10}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setOpSuccessful(false);
                }}
              />
              <div className="form-text">Will be used by the member to login</div>
            </div>

            <div 
              className="mb-3"
              style={{
                display: (role === 'agent' || role === 'tpsf') ? 'block' : 'none',
              }}
            >
              <label>Supported Providers</label>
              <div className="row">
                {
                  (supportedProviders && supportedProviders.length > 0) ?
                  supportedProviders.map((item, i) => {
                    return (
                      <div
                        className="col-md-12"
                        key={i}
                      >
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-6 mb-2">
                                <label className="form-label" style={{ fontSize: "12px", }}>Select Provider</label>
                                <select
                                  value={item.id || ""}
                                  className="form-control"
                                  onChange={(e) => {
                                    handleProviderIdChange(i, e.target.value)
                                  }}
                                >
                                  <option value=""></option>
                                  {
                                    (appContext.supportedProviders && appContext.supportedProviders.length > 0) ?
                                    appContext.supportedProviders.map((_prov, _i) => {
                                      return (
                                        <option
                                          key={_i}
                                          value={_prov.id}
                                        >
                                          {_prov.name}
                                        </option>
                                      )
                                    }):""
                                  }
                                </select>
                              </div>

                              <div className="col-6 mb-2">
                                <label className="form-label" style={{ fontSize: "12px" }}>Till Number</label>
                                <input
                                  className="form-control"
                                  value={item.tillNumber}
                                  onChange={(e) => {
                                    handleTillNumberChange(i, e.target.value)
                                  }}
                                />
                              </div>

                              {
                                (item.providerType === 'bank') ?
                                <>
                                  <div className="col-6 mb-2">
                                    <label className="form-label" style={{ fontSize: "12px" }}>Bank Account Number</label>
                                    <input
                                      className="form-control"
                                      value={item.accountNumber}
                                      onChange={(e) => {
                                        handleAccountNumberChange(i, e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="col-6 mb-2">
                                    <label className="form-label" style={{ fontSize: "12px" }}>Device Type</label>
                                    <input
                                      className="form-control"
                                      value={item.deviceType}
                                      onChange={(e) => {
                                        handleDeviceTypeChange(i, e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="col-6 mb-2">
                                    <label className="form-label" style={{ fontSize: "12px" }}>Device Serial Number</label>
                                    <input
                                      className="form-control"
                                      value={item.deviceSerialNumber}
                                      onChange={(e) => {
                                        handleDeviceSerialNumberChange(i, e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="col-6 mb-2">
                                    <label className="form-label" style={{ fontSize: "12px" }}>Device Imei 1</label>
                                    <input
                                      className="form-control"
                                      value={item.deviceImei1}
                                      onChange={(e) => {
                                        handleDeviceImei1Change(i, e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="col-6 mb-2">
                                    <label className="form-label" style={{ fontSize: "12px" }}>Device Imei 2</label>
                                    <input
                                      className="form-control"
                                      value={item.deviceImei2}
                                      onChange={(e) => {
                                        handleDeviceImei2Change(i, e.target.value)
                                      }}
                                    />
                                  </div>
                                </>
                                :""
                              }

                              {
                                (item.providerType === 'mobile') ?
                                <>
                                </>
                                :""
                              }

                              <div className="col-12 mb-2 text-end">
                                <button
                                  className="btn btn-sm btn-danger"
                                >
                                  <MdOutlineDelete 
                                    size={20}
                                    onClick={() => {
                                      deleteProvider(i)
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :""
                }
                <div className="col-md-12">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={addProvider}
                  >
                    <MdAdd size={16}/> Add Provider
                  </button>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label>Location</label>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="form-label font-regular">Region*</label>
                  <select 
                    className="form-control"
                    onChange={(e) => {
                      handleLocationChange('region', ((e.target.value) ? JSON.parse(e.target.value) : ""))
                    }}
                    value={(location) ? JSON.stringify(location.region) : ''}
                  >
                    <option value=""></option>
                    {
                      (allRegions && allRegions.length > 0) ?
                      allRegions.map((item, i) => {
                        return (
                          <option
                            value={JSON.stringify({ name: item.name, id: item.id })}
                            key={i}
                          >
                            {item.name}
                          </option>
                        )
                      }): ""
                    }
                  </select>
                </div>

                <div className="col-md-4 mb-3">
                  <label className="form-label font-regular">District*</label>
                  <select 
                    className="form-control"
                    onChange={(e) => {
                      handleLocationChange('district', ((e.target.value) ? JSON.parse(e.target.value) : ""))
                    }}
                    value={(location) ? JSON.stringify(location.district) : ''}
                  >
                    <option value=""></option>
                    {
                      (districts && districts.length > 0) ?
                      districts.map((item, i) => {
                        return (
                          <option
                            value={JSON.stringify({ name: item.name, id: item.id })}
                            key={i}
                          >
                            {item.name}
                          </option>
                        )
                      }): ""
                    }
                  </select>
                </div>

                <div className="col-md-4 mb-3">
                  <label className="form-label font-regular">Ward or Village</label>
                  <select 
                    className="form-control"
                    onChange={(e) => {
                      handleLocationChange('ward', ((e.target.value) ? JSON.parse(e.target.value) : ""))
                    }}
                    value={(location) ? JSON.stringify(location.ward) : ''}
                  >
                    <option value=""></option>
                    {
                      (wards && wards.length > 0) ?
                      wards.map((item, i) => {
                        return (
                          <option
                            value={JSON.stringify({ name: item.name, id: item.id })}
                            key={i}
                          >
                            {item.name}
                          </option>
                        )
                      }): ""
                    }
                  </select>
                </div>
              </div>
            </div>

            <div className="mb-3 col-12">
              <label className="form-label">Role*</label>
              <select
                type="text"
                className="form-control"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                  setOpSuccessful(false);
                }}
                disabled={(props.role) ? true : false}
              >
                <option value="agent">Agent</option>
                <option value="tpsf">TPSF</option>
                <option value="manager">Manager</option>
                <option value="admin">Admin</option>
                <option value="cashier">Main Cashier</option>
              </select>
            </div>

            {
              (role === 'tpsf' || role === 'manager' || role === 'agent') ?
              <div className="mb-3 col-12">
                <label className="form-label">Branch</label>
                <select
                  type="text"
                  className="form-control"
                  value={branchId}
                  onChange={(e) => {
                    setBranchId(e.target.value);
                    setOpSuccessful(false);
                  }}
                  disabled={(props.branchId) ? true : false}
                >
                  <option value=""></option>
                  {
                    (appContext.allBranches && appContext.allBranches.length) ?
                    appContext.allBranches.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>{item.name}</option>
                      )
                    })
                    :""
                  }
                </select>
              </div>
              :""
            }

            {
              (role === 'agent') ?
              <>
                
              </>
              :
              <>
                <div className="mb-3 col-6">
                  <label className="form-label">Password*</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setOpSuccessful(false);
                    }}
                  />
                  <div className="form-text">Will be used by the member to login</div>
                </div>

                <div className="mb-3 col-6">
                  <label className="form-label">Confirm*</label>
                  <input
                    type="password"
                    className="form-control"
                    value={confirm}
                    onChange={(e) => {
                      setConfirm(e.target.value);
                      setOpSuccessful(false);
                    }}
                  />
                </div>
              </>
            }

            


            <div className="col-md-12 text-end">
              <button
                className="btn btn-primary btn-padded mSupportLoading"
                onClick={() => {
                  appContext.activateDialog({
                    message: `User ${String(fullname).toUpperCase()} will be registered, continue?`,
                    onConfirm: register,
                  })
                }}
              >
                Register
              </button>
            </div>
          </div>
        </div>

      </div>
      

    </div>
  )
}