import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { callApi, formatMoney, getInlineLoader } from "../Helpers";
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { MdAddCircleOutline, MdCheckCircle, MdEdit, MdPerson, MdSubject } from "react-icons/md";
import { BiEdit, BiSend } from "react-icons/bi";
import { formatDate } from "../Helpers";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ receiptText, setReceiptText ] = useState("");

  async function getFloatRequestData() {
    await callApi(
      "get_float_request_data.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setData(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function init() {
    //run initializations here
    setReady(false);
    await getFloatRequestData();
    setReady(true);
  }

  async function confirmRequest() {

    const receiptFile = document.getElementById('_receiptFile').files[0];
    if(
      receiptFile ||
      receiptText.trim().length > 0
    ) {
      setLoading(true);
      await callApi(
        'confirm_float_request.php',
        {
          floatRequestId: data.id,
          receiptFile,
          receiptText,
        }
      ).then((response) => {
        if(response.status === 1) {
          appContext.tellMessage("Request was confirmed successfully")
          getFloatRequestData();
          appContext.getAllAgentsFloatRequests();
          appContext.getAllTpsfFloatRequests();
          appContext.getBranchTpsfFloatRequests();
          appContext.getMyFloatRequests();
          
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    } else {
      appContext.tellError("Please provide atleast receipt file or receipt text");
    }

    
  }

  function viewRequester() {
    appContext.navTo({
      item: 'side-view',
      subItem: 'staff-options',
      extraItem: data.userId,
    })
  }

  function viewAgent() {
    appContext.navTo({
      item: 'side-view',
      subItem: 'staff-options',
      extraItem: data.agentId,
    })
  }

  function viewBranch() {
    appContext.navTo({
      item: 'side-view',
      subItem: 'branch-options',
      extraItem: data.branchId,
    })
  }

  async function forwardRequest() {
    setLoading(true);
    await callApi(
      'forward_float_request.php',
      {
        floatRequestId: data.id,
      }
    ).then((response) => {
      if(response.status === 1) {
        appContext.tellMessage("Request was forwarded successfully")
        getFloatRequestData();
        appContext.getBranchAgentsFloatRequests();
        appContext.getAllTpsfFloatRequests();
        appContext.getMyFloatRequests();
        
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])
  
  if(ready && data) {
    return (
      <div 
        className="container"
      >
        <div className="row">

          <div className="col-md-12">
            <h6 className="main-section-title font-semi-bold text-primary">
              {formatMoney(data.amount)} Tsh <br/>
            </h6>

            <h6
              className="font-regular text-primary mb-4"
              style={{
                fontSize: "22px",
                wordWrap: "break-word", // Break long words to wrap them
              }}
            >
              FLOAT - {data.providerData?.name}
            </h6>

            <h6>
              <span 
                className={
                  (data.status === 'pending') ?
                  "category-badge-sm bg-primary":
                  (data.status === 'confirmed') ?
                  "category-badge-sm bg-success text-light" : "category-badge-sm bg-secondary"
                }
              >
                {data.status}
              </span>
            </h6>

            <p
              style={{
                fontSize: "14px",
              }}
              className=""
            >
              Requested On <i>{formatDate(data.requestDate).substring(0, 10)}</i> 
            </p>

            {
              (data.requestForAgent === 'yes') ?
              <>
                <p
                  style={{
                    fontSize: "14px",
                    padding: "10px"
                  }}
                  className="bg-secondary font-semi-bold"
                >
                  Requested by TPSF for Agent <i className="font-bold">{data.agentData.fullname}</i> 
                </p>
              </>
              :""
            }
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  
                  {
                    (
                      data.status === 'pending' &&
                      (
                        (
                          (
                            appContext.currentUserData.role === 'admin' || 
                            appContext.currentUserData.role === 'cashier' || 
                            appContext.currentUserData.role === 'manager'
                          ) && data.requestAs === 'tpsf'
                        ) 
                        ||
                        (
                          (
                            appContext.currentUserData.role === 'tpsf' 
                          ) && data.requestAs === 'agent'
                        ) 
                      ) 
                      
                    ) ?
                    <div className="col-12">
                      <div 
                        className="text-center"
                        style={{
                          maxWidth: "300px",
                          margin: "0 auto",
                        }}
                      >

                        <div className="mb-4">
                          <label className="form-label font-semi-bold">Receipt Text</label>
                          <textarea
                            className="form-control"
                            value={receiptText}
                            onChange={(e) => setReceiptText(e.target.value)}
                          ></textarea>
                        </div>

                        <div className="mb-4">
                          <label className="form-label font-semi-bold">Receipt File</label>
                          <input
                            className="form-control"
                            type="file"
                            accept="image/*"
                            id="_receiptFile"
                          />
                        </div>

                        <button
                          className="btn btn-padded btn-primary"
                          style={{
                            width: "100%",
                          }}
                          onClick={() => {
                            appContext.activateDialog({
                              message: "Confirm that this request has been processed?",
                              onConfirm: confirmRequest,
                            })
                          }}
                        >
                          Confirm Transaction {(data.requestForAgent === 'yes') ? "To Agent" :""}
                        </button>

                      </div>
                      
                      <hr/>
                      
                    </div>
                    :""
                  }

                  {
                    (
                      appContext.currentUserData.role === 'tpsf' &&
                      data.requestAs === 'agent' &&
                      data.status === 'pending' &&
                      data.isForwarded === 'no'
                    ) ?
                    <div className="col-4 col-md-4">
                      <button
                        className="btn btn-sm"
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          forwardRequest()
                        }}
                      >
                        <BiSend size={40}/><br/>
                        Forward
                      </button>
                    </div>
                    :""
                  }

                  <div className="col-4 col-md-4">
                    <button
                      className="btn btn-sm"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => {
                        viewRequester()
                      }}
                    >
                      <MdPerson size={40}/><br/>
                      View {data.requestAs}
                    </button>
                    
                  </div>

                  {
                    (data.requestForAgent === 'yes') ?
                    <div className="col-4 col-md-4">
                      <button
                        className="btn btn-sm"
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          viewAgent()
                        }}
                      >
                        <MdPerson size={40}/><br/>
                        View Agent
                      </button>
                      
                    </div>
                    :""
                  }

                  <div className="col-4 col-md-4">
                    <button
                      className="btn btn-sm"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => {
                        viewBranch()
                      }}
                    >
                      <PiBuildingOfficeBold size={40}/><br/>
                      View Branch
                    </button>
                  </div>

                  
                  
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}