import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle } from "react-icons/md";

export default function View({ tpsfId }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ title, setTitle ] = useState("");
  const [ amount, setAmount ] = useState(0);
  const [ startDate, setStartDate ] = useState("");
  const [ endDate, setEndDate ] = useState("");
  const [ tpsfData, setTpsfData ] = useState(null);
  const [ ready, setReady ] = useState(false);
  const [ providerId, setProviderId ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  async function init() {
    //run initializations here if any
    setReady(false);
    await getTpsfData();
    await appContext.getSupportedProviders();
    setReady(true);
  }

  async function getTpsfData() {
    await callApi('get_staff_data.php', { id: tpsfId }).then(response => {
      if(response.status === 1) {
        setTpsfData(response.data);
      }
    })
  }

  function resetInputFields() {
    setTitle("");
    setStartDate("");
    setEndDate("");
    setAmount(0);
  }

  async function create() {
    if(!loading) {
      if(!isNaN(amount) && Number(amount) > 0) {
        if(title.trim().length > 0) {
          if(startDate.trim().length > 0) {
            if(endDate.trim().length > 0) {
              if(String(tpsfId).trim().length > 0) {
                //..
                if(String(providerId).trim().length > 0) {
                  setLoading(true);
                  await callApi(
                    "create_tpsf_float_goal.php",
                    {
                      title,
                      amount,
                      startDate,
                      endDate,
                      tpsfId,
                      providerId
                    }
                  ).then(response => {
                    if(response.status === 1) {
                      appContext.getAllTasks();
                      setOpSuccessful(true);
                      resetInputFields();
                      appContext.tellMessage("Goal was created");
                    } else {
                      appContext.tellError(response.msg);
                    }
                  });
                  setLoading(false);
                } else {
                  appContext.tellError("Invalid provider")
                }
                //..
              } else {
                appContext.tellError("Invalid branch");
              }
            } else {
              appContext.tellError("Invalid end date");
            }
          } else {
            appContext.tellError("Invalid start date");
          }
        } else {
          appContext.tellError("Invalid task title");
        }
      } else {
        appContext.tellError("Invalid amount");
      }
    } else {
      appContext.tellInfo("Loading, please wait....")
    }
  }

  

  useEffect(() => {
    init();
  }, [ ])


  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  if(ready && tpsfData) {
    return (
      <div 
        className="container"
        
      >
        <div className="mFormType1">
          
          <h6 className="section-title">Create Float Goal For TPSF</h6>
        
          <div className="body">
            <div className="row">
              {
                (opSuccessful) ?
                <div className="col-md-12">
                  <div
                    className="bg-background text-center"
                    style={{
                      padding: "20px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <h6
                      className="font-semi-bold text-success"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      <MdCheckCircle size={60}/>
                      <br/>
                      Foat Goal was created successfully
                    </h6>
                  </div>
                </div>
                :""
              }

              <div className="col-md-12 mb-4">
                <label className="form-label">Name of TPSF</label>
                <h3
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {tpsfData.fullname}
                </h3>
              </div>
  
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setOpSuccessful(false);
                    }}
                  />
                  <div className="form-text">Title for this float goal</div>
                </div>

                <div className="mb-3">
                  <label className="form-label">Amount in Tsh</label>
                  <input
                    type="text"
                    className="form-control"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      setOpSuccessful(false);
                    }}
                  />
                  <div className="form-text">Amount to be reached</div>
                </div>

                <div className="mb-3">
                  <label className="">Select Provider</label>
                  <select
                    className="form-control"
                    value={providerId}
                    onChange={(e) => setProviderId(e.target.value)}
                  >
                    <option value=""></option>
                    {
                      (appContext.supportedProviders && appContext.supportedProviders.length > 0) ?
                      appContext.supportedProviders.map((item, i) => {
                        return (
                          <option
                            key={i}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        )
                      }):""
                    }
                  </select>
                </div>
  
                <div className="mb-3">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Start Date*</label>
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                          setOpSuccessful(false);
                        }}
                      />
                    </div>
  
                    <div className="col-md-6 mb-3">
                      <label className="form-label">End Date*</label>
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                          setOpSuccessful(false);
                        }}
                      />
                    </div>
                    
                  </div>
                </div>
  
              </div>
  
              <div className="col-md-12 text-end">
                <button
                  className="btn btn-primary btn-padded mSupportLoading"
                  onClick={() => {
                    appContext.activateDialog({
                      message: `This float goal will be created for ${tpsfData.fullname}, continue?`,
                      onConfirm: create,
                    })
                  }}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
  
        </div>
        
  
      </div>
    )
  } else {
    return (
      <div 
        className="container"
        
      >
        <div className="mFormType1">
          
          <h6 className="section-title">Create Float Goal For TPSF</h6>
        
          <div className="body">
            <div style={{ width: "100%" }} className="mSupportLoading">
              {getInlineLoader()}
            </div>
          </div>
  
        </div>
        
  
      </div>
    )
  }
}