import { useState, useEffect, createContext } from "react";
import { getMainView, getViewerView, getSideViewerView, callApi } from "./Helpers";
import { useParams } from "react-router-dom";
import Viewer from "./components/Viewer";
import MainBody from "./components/MainBody";
import Backdrop from "./components/Backdrop";
import SideViewer from "./components/SideViewer";
import ConfirmDialog from "./components/ConfirmDialog";
import { ToastContainer, toast } from 'react-toastify';
import SideBar from "./components/SideBar";
import TopBar from "./components/TopBar";
import MainMenuBtn from "./components/MainMenuBtn";
import Login from "./views/Login";
import OverlayLoader from "./components/OverlayLoader";
import MainLoader from "./components/MainLoader";

export const AppContext = createContext(null);

export default function App(props) {

  const [ ready, setReady ] = useState(false); //for checking if app is ready

  const { _navItem, _navSubItem, _navExtraItem, _navMoreItem } = useParams(); //for routing purposes

  const [ navItem, setNavItem ] = useState(_navItem); //routing 
  const [ navSubItem, setNavSubItem ] = useState(_navSubItem); //routing
  const [ navExtraItem, setNavExtraItem ] = useState(_navExtraItem); //routing
  const [ navMoreItem, setNavMoreItem ] = useState(_navMoreItem); //routing

  const [ showViewer, setShowViewer ] = useState(false); //controlling the display of Viewer component
  const [ viewerView, setViewerView ] = useState(null); //the view to be shown in viewer

  const [ showSideViewer, setShowSideViewer ] = useState(false); //controlling the display of SideViewer component
  const [ sideViewerView, setSideViewerView ] = useState(null); //the view to be shown in viewer

  const [ mainView, setMainView ] = useState(null); //the view tobe shown in MainBody

  const [ showOverlayLoader, setShowOverlayLoader ] = useState(false); //controlling the display of OverlayLoader

  const [ showConfirmDialog, setShowConfirmDialog ] = useState(false);
  const [ confirmDialogMessage, setConfirmDialogMessage ] = useState("");
  const [ confirmDialogAction, setConfirmDialogAction ] = useState("");

  const [ isSideBarOpen, setIsSideBarOpen ] = useState(false); //controls open and close for sidebar in mobile devices

  const [ auth, setAuth ] = useState(false); //track user authorization status

  //currentSession
  const [ currentUserData, setCurrentUserData ] = useState(null);

  //App level data
  const [ allBranches, setAllBranches ] = useState(null);
  const [ allStaff, setAllStaff ] = useState(null);
  const [ allTasks, setAllTasks ] = useState(null);
  const [ branchTasks, setBranchTasks ] = useState(null);
  const [ myTasks, setMyTasks ] = useState(null);
  const [ myBranchData, setMyBranchData ] = useState(null);

  const [ myFloatRequests, setMyFloatRequests ] = useState(null);
  const [ allFloatRequests, setAllFloatRequests ] = useState(null);
  const [ allAgentsFloatRequests, setAllAgentsFloatRequests ] = useState(null);
  const [ branchAgentsFloatRequests, setBranchAgentsFloatRequests ] = useState(null);
  const [ allTpsfFloatRequests, setAllTpsfFloatRequests ] = useState(null);
  const [ branchTpsfFloatRequests, setBranchTpsfFloatRequests ] = useState(null);
  const [ branchTpsfCashRequests, setBranchTpsfCashRequests ] = useState(null);
  const [ supportedProviders, setSupportedProviders ] = useState(null);
  const [ myFloatBalance, setMyFloatBalance ] = useState(0);

  const [ myCashRequests, setMyCashRequests ] = useState(null);
  const [ allCashRequests, setAllCashRequests ] = useState(null);
  const [ allAgentsCashRequests, setAllAgentsCashRequests ] = useState(null);
  const [ branchAgentsCashRequests, setBranchAgentsCashRequests ] = useState(null);
  const [ allTpsfCashRequests, setAllTpsfCashRequests ] = useState(null);
  const [ myCashBalance, setMyCashBalance ] = useState(0);

  const [ totalIncome, setTotalIncome ] = useState(0);
  const [ totalExpenses, setTotalExpenses ] = useState(0);



  async function init() {
    /**
     * Initialize the app here
     */

    setReady(false);

    await authCheck().then(async (_auth) => {
      if(_auth) {
        await getCurrentUserData();
      }
    })

    //turn off loaders after initialization
    setReady(true);
  }

  function navBack() {
    window.history.back();
    setShowOverlayLoader(false);
  }

  function tellError(msg) {
    toast.error(msg);
  }

  function tellInfo(msg) {
    toast.info(msg);
  }

  function tellWarning(msg) {
    toast.warn(msg);
  }

  function tellMessage(msg) {
    toast.success(msg);
  }

  function refresh() {
    /**
     * This function refreshes the whole app
     */
    window.location.reload(); //remember to optimize
  }

  function navTo(nav) {
    /**
     * This function handles navigation inside the app
     * Utilizing Hash based routing
     * nav is the object supporting the following keys: item, subItem, extraItem, moreItem
     */
    if(nav) {
      //..
      
      let url = '';
      if(nav.item) {
        url = `#/${nav.item}/`
      }

      if(nav.subItem) {
        url += `${nav.subItem}/`
      }

      if(nav.extraItem) {
        url += `${nav.extraItem}/`
      }

      if(nav.moreItem) {
        url += `${nav.moreItem}/`
      }

      window.location.href = url;
      //..
    }
  }

  async function getCurrentUserData() {
    await callApi('get_current_user_data.php', { }).then(response => {
      if(response.status === 1) {
        setCurrentUserData(response.data);
      }
    })
  }

  async function getAllBranches() {
    await callApi('get_all_branches.php', { }).then(response => {
      if(response.status === 1) {
        setAllBranches(response.data);
      }
    })
  }

  async function getSupportedProviders() {
    await callApi('get_all_supported_providers.php', { }).then(response => {
      if(response.status === 1) {
        setSupportedProviders(response.data);
      }
    })
  }

  async function getAllTasks() {
    await callApi('get_all_tasks.php', { }).then(response => {
      if(response.status === 1) {
        setAllTasks(response.data);
      }
    })
  }

  async function getBranchTasks() {
    await callApi('get_branch_tasks.php', { }).then(response => {
      if(response.status === 1) {
        setBranchTasks(response.data);
      }
    })
  }

  async function getMyTasks() {
    await callApi('get_my_tasks.php', { }).then(response => {
      if(response.status === 1) {
        setMyTasks(response.data);
      }
    })
  }

  async function getMyBranchData() {
    await callApi('get_my_branch_data.php', { }).then(response => {
      if(response.status === 1) {
        setMyBranchData(response.data);
      }
    })
  }

  async function getMyFloatBalance() {
    await callApi('get_my_float_balance.php', { }).then(response => {
      if(response.status === 1) {
        setMyFloatBalance(response.data);
      }
    })
  }

  async function getMyFloatRequests() {
    await callApi('get_my_float_requests.php', { }).then(response => {
      if(response.status === 1) {
        setMyFloatRequests(response.data);
      }
    })
  }

  async function getAllFloatRequests() {
    await callApi('get_all_float_requests.php', { }).then(response => {
      if(response.status === 1) {
        setAllFloatRequests(response.data);
      }
    })
  }

  async function getAllAgentsFloatRequests() {
    await callApi('get_all_agents_float_requests.php', { }).then(response => {
      if(response.status === 1) {
        setAllAgentsFloatRequests(response.data);
      }
    })
  }

  async function getBranchAgentsFloatRequests() {
    await callApi('get_branch_agents_float_requests.php', { }).then(response => {
      if(response.status === 1) {
        setBranchAgentsFloatRequests(response.data);
      }
    })
  }

  async function getAllTpsfFloatRequests() {
    await callApi('get_all_tpsf_float_requests.php', { }).then(response => {
      if(response.status === 1) {
        setAllTpsfFloatRequests(response.data);
      }
    })
  }

  async function getBranchTpsfFloatRequests() {
    await callApi('get_branch_tpsf_float_requests.php', { }).then(response => {
      if(response.status === 1) {
        setBranchTpsfFloatRequests(response.data);
      }
    })
  }

  async function getBranchTpsfCashRequests() {
    await callApi('get_branch_tpsf_cash_requests.php', { }).then(response => {
      if(response.status === 1) {
        setBranchTpsfCashRequests(response.data);
      }
    })
  }

  async function getMyCashBalance() {
    await callApi('get_my_cash_balance.php', { }).then(response => {
      if(response.status === 1) {
        setMyCashBalance(response.data);
      }
    })
  }

  async function getMyCashRequests() {
    await callApi('get_my_cash_requests.php', { }).then(response => {
      if(response.status === 1) {
        setMyCashRequests(response.data);
      }
    })
  }

  async function getAllCashRequests() {
    await callApi('get_all_cash_requests.php', { }).then(response => {
      if(response.status === 1) {
        setAllCashRequests(response.data);
      }
    })
  }

  async function getAllAgentsCashRequests() {
    await callApi('get_all_agents_cash_requests.php', { }).then(response => {
      if(response.status === 1) {
        setAllAgentsCashRequests(response.data);
      }
    })
  }

  async function getBranchAgentsCashRequests() {
    await callApi('get_branch_agents_cash_requests.php', { }).then(response => {
      if(response.status === 1) {
        setBranchAgentsCashRequests(response.data);
      }
    })
  }

  async function getTotalIncome() {
    await callApi('get_total_income.php', { }).then(response => {
      if(response.status === 1) {
        setTotalIncome(response.data);
      }
    })
  }

  async function getTotalExpenses() {
    await callApi('get_total_expenses.php', { }).then(response => {
      if(response.status === 1) {
        setTotalExpenses(response.data);
      }
    })
  }


  async function getAllTpsfCashRequests() {
    await callApi('get_all_tpsf_cash_requests.php', { }).then(response => {
      if(response.status === 1) {
        setAllTpsfCashRequests(response.data);
      }
    })
  }


  function doesCurrentUserOwnTask(taskId) {
    if(taskId) {
      if(currentUserData && currentUserData.tasks) {
        const _tasks = JSON.parse(currentUserData.tasks);

        for (const _task of _tasks) {
          if(Number(_task) === Number(taskId)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  function getAllStaff() {
    return new Promise(async resolve => {
      await callApi('get_all_staff.php', { }).then(response => {
        if(response.status === 1) {
          setAllStaff(response.data);
          resolve(response.data)
        } else {
          resolve(null);
        }

      })
    })
  }

  function authCheck() {
    return new Promise(async resolve => {
      await callApi('auth_check.php', { }).then(response => {
        if(response.status === 1) {
          setAuth(true);
          resolve(true)
        } else {
          setAuth(false);
          resolve(false);
        }
      })
    })
  }

  function activateDialog(params) {
    let {
      message,
      onConfirm
    } = params;
    setConfirmDialogAction(() => { return onConfirm });
    setConfirmDialogMessage(message)
    setShowConfirmDialog(true);
  }

  function getAllRegions() {
    return new Promise(async (resolve) => {
      await callApi("np_get_all_regions.php", { }).then(response => {
        if(response.status === 1) {
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  function getDistricts(params) {
    return new Promise(async (resolve) => {
      let {
        regionId,
      } = params;

      await callApi("np_get_districts.php", { regionId }).then(response => {
        if(response.status === 1) {
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  function getWards(params) {
    return new Promise(async (resolve) => {
      let {
        districtId,
      } = params;

      await callApi("np_get_wards.php", { districtId }).then(response => {
        if(response.status === 1) {
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  function getStreets(params) {
    return new Promise(async (resolve) => {
      let {
        wardId,
      } = params;

      await callApi("np_get_streets.php", { wardId }).then(response => {
        if(response.status === 1) {
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  function getRoads(params) {
    return new Promise(async (resolve) => {
      let {
        streetId,
      } = params;

      await callApi("np_get_roads.php", { streetId }).then(response => {
        if(response.status === 1) {
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  function getStaffName(_staffId) {
    let _allStaff = null;
    if(allStaff && allStaff.length > 0) {
      _allStaff = allStaff;
    } else {
      (
        async () => _allStaff = await getAllStaff()
      )()
    }

    if(_allStaff) {
      let _name = "";

      for (const _staff of _allStaff) {
        if(Number(_staff.id) === Number(_staffId)) {
          _name = _staff.fullname;
          return _name;
        }
      }
    }

    return "";
  }


  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(isSideBarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [ isSideBarOpen ])

  useEffect(() => {
    setNavItem(_navItem);
    setNavSubItem(_navSubItem);
    setNavExtraItem(_navExtraItem);
    setNavMoreItem(_navMoreItem);
  }, [ _navItem, _navSubItem, _navExtraItem, _navMoreItem ])

  useEffect(() => {
    if(currentUserData) {
      //handle current user data changes here
    }
  }, [ currentUserData ])


  useEffect(() => {
    
    //check for viewers
    if(navItem === 'view' ) {
      //activate viewer
      setShowViewer(true);
      setViewerView(getViewerView(appContext));

      //hide other viewers
      setShowSideViewer(false)
      setSideViewerView(null);
      
    } else if(navItem === 'side-view' ) {
      //activate viewer
      setShowSideViewer(true);
      setSideViewerView(getSideViewerView(appContext));

      //hide other viewers
      setShowViewer(false)
      setViewerView(null);
      
    } else {
      //just set normal views
      setShowViewer(false);
      setShowSideViewer(false)

      setViewerView(null);
      setSideViewerView(null);
      
      const _mainView = getMainView(appContext)
      if(_mainView) {
        setMainView(_mainView);
      }
    }
  }, [ navItem, navSubItem, navExtraItem, navMoreItem ]);

  const appContext = {
    refresh,
    navTo,
    mainView,
    viewerView,
    setShowOverlayLoader,
    navItem,
    navSubItem,
    navExtraItem,
    navMoreItem,
    setShowViewer,
    showViewer,
    navBack,
    showSideViewer,
    setShowSideViewer,
    sideViewerView,
    activateDialog,
    setShowConfirmDialog,
    confirmDialogAction,
    confirmDialogMessage,
    showConfirmDialog,
    tellError,
    tellInfo,
    tellMessage,
    tellWarning,
    isSideBarOpen,
    setIsSideBarOpen,
    showOverlayLoader,
    setShowOverlayLoader,
    auth,
    currentUserData,
    getAllRegions,
    getDistricts,
    getWards,
    getStreets,
    getRoads,
    getAllBranches,
    allBranches,
    getAllStaff,
    allStaff,
    allTasks,
    getAllTasks,
    getStaffName,
    myTasks,
    getMyTasks,
    doesCurrentUserOwnTask,
    getSupportedProviders,
    supportedProviders,
    myFloatRequests,
    getMyFloatRequests,
    getAllFloatRequests,
    allFloatRequests,
    allAgentsFloatRequests,
    allTpsfFloatRequests,
    getAllAgentsFloatRequests,
    getAllTpsfFloatRequests,
    getMyFloatBalance,
    myFloatBalance,
    myCashRequests,
    getMyCashRequests,
    getAllCashRequests,
    allCashRequests,
    allAgentsCashRequests,
    allTpsfCashRequests,
    getAllAgentsCashRequests,
    getAllTpsfCashRequests,
    getMyCashBalance,
    myCashBalance,
    totalIncome,
    totalExpenses,
    getTotalIncome,
    getTotalExpenses,
    myBranchData,
    getMyBranchData,
    branchTasks,
    getBranchTasks,
    branchTpsfFloatRequests,
    getBranchTpsfFloatRequests,
    getBranchTpsfCashRequests,
    branchTpsfCashRequests,
    getBranchAgentsFloatRequests,
    branchAgentsFloatRequests,
    getBranchAgentsCashRequests,
    branchAgentsCashRequests,
  }

  if(ready) {
    if(auth) {
      if(currentUserData) {
        return (
          <AppContext.Provider value={appContext}>
            <MainBody/>
            <TopBar/>
            <MainMenuBtn/>
            <SideBar/>
            <Viewer/>
            <SideViewer/>
            <ConfirmDialog/>
            <OverlayLoader/>
            <ToastContainer position="bottom-right" />
          </AppContext.Provider>
        )
      } else {
        return (
          <AppContext.Provider value={appContext}>
            <ToastContainer position="bottom-right" />
            <MainLoader/>
          </AppContext.Provider>
        )
      }
    } else {
      return (
        <AppContext.Provider value={appContext}>
          <OverlayLoader/>
          <ToastContainer position="bottom-right" />
          <Login/>
        </AppContext.Provider>
      )
    }
  } else {
    return (
      <AppContext.Provider value={appContext}>
        <ToastContainer position="bottom-right" />
        <MainLoader/>
      </AppContext.Provider>
    )
  }
}