import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle } from "react-icons/md";

export default function View({ branchId }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ title, setTitle ] = useState("");
  const [ amount, setAmount ] = useState(0);
  const [ startDate, setStartDate ] = useState("");
  const [ endDate, setEndDate ] = useState("");
  const [ branchData, setBranchData ] = useState(null);
  const [ branchTpsfs, setBranchTpsfs ] = useState(null);
  const [ providerId, setProviderId ] = useState("");
  const [ tpsfs, setTpsfs ] = useState(null);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  async function init() {
    //run initializations here if any
    setReady(false);
    await getBranchData();
    await getBranchTpsfs();
    await appContext.getSupportedProviders();
    setReady(true);
  }

  function handleTpsfGoalAmountChange(index, value) {
    if(!isNaN(value)) {
      if(Number(value) >= 0) {
        setTpsfs(_prev => {
          const _new = [ ..._prev ];

          _new[index].amount = Number(value);

          return _new;
        })
      }
    }
  }


  async function getBranchData() {
    await callApi('get_branch_data.php', { id: branchId }).then(response => {
      if(response.status === 1) {
        setBranchData(response.data);
      }
    })
  }

  async function getBranchTpsfs() {
    await callApi('get_branch_tpsfs.php', { id: branchId }).then(response => {
      if(response.status === 1) {
        setBranchTpsfs(response.data);
      }
    })
  }

  function resetInputFields() {
    setTitle("");
    setStartDate("");
    setEndDate("");
    setAmount(0);
    
  }

  async function create() {
    if(!loading) {
      if(!isNaN(amount) && Number(amount) > 0) {
        if(title.trim().length > 0) {
          if(startDate.trim().length > 0) {
            if(endDate.trim().length > 0) {
              if(String(branchId).trim().length > 0) {
                if(String(providerId).trim().length > 0) {
                  //...
                  setLoading(true);
                  await callApi(
                    "create_branch_float_goal.php",
                    {
                      title,
                      amount,
                      startDate,
                      endDate,
                      branchId,
                      tpsfs: JSON.stringify(tpsfs),
                      providerId,
                    }
                  ).then(response => {
                    if(response.status === 1) {
                      appContext.getAllTasks();
                      setOpSuccessful(true);
                      resetInputFields();
                      appContext.tellMessage("Goal was created");
                    } else {
                      appContext.tellError(response.msg);
                    }
                  });
                  setLoading(false);
                  //...
                } else {
                  appContext.tellError("Invalid Provider");
                }
                
              } else {
                appContext.tellError("Invalid branch");
              }
            } else {
              appContext.tellError("Invalid end date");
            }
          } else {
            appContext.tellError("Invalid start date");
          }
        } else {
          appContext.tellError("Invalid task title");
        }
      } else {
        appContext.tellError("Invalid amount");
      }
    } else {
      appContext.tellInfo("Loading, please wait....")
    }
  }

  

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(branchTpsfs) {
      const _tpsfs = [];

      for (const _tpsf of branchTpsfs) {
        _tpsfs.push({
          userId: _tpsf.id,
          fullname: _tpsf.fullname,
          amount: 0,
        })
      }

      setTpsfs(_tpsfs);
    }
  }, [ branchTpsfs ])

  useEffect(() => {
    if(amount) {
      if(!isNaN(amount)) {
        if(Number(amount) > 0) {
          if(tpsfs) {
            if(tpsfs.length > 0) {
              setTpsfs(_prev => {
                const _new = [];
                let _goal = Math.floor(amount/_prev.length);

                for (const _item of _prev) {
                  _item.amount = _goal;
                  _new.push(_item);
                }

                return _new;

              })
            }
          }
        }
      }
    }
  }, [ amount ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  if(ready && branchData) {
    return (
      <div 
        className="container"
        
      >
        <div className="mFormType1">
          
          <h6 className="section-title">Create Float Goal For Branch</h6>
        
          <div className="body">
            <div className="row">
              {
                (opSuccessful) ?
                <div className="col-md-12">
                  <div
                    className="bg-background text-center"
                    style={{
                      padding: "20px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <h6
                      className="font-semi-bold text-success"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      <MdCheckCircle size={60}/>
                      <br/>
                      Foat Goal was created successfully
                    </h6>
                  </div>
                </div>
                :""
              }

              <div className="col-md-12 mb-4">
                <label className="form-label">Name of the Branch</label>
                <h3
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {branchData.name}
                </h3>
              </div>
  
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setOpSuccessful(false);
                    }}
                  />
                  <div className="form-text">Title for this float goal</div>
                </div>

                <div className="mb-3">
                  <label className="form-label">Amount in Tsh</label>
                  <input
                    type="text"
                    className="form-control"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      setOpSuccessful(false);
                    }}
                  />
                  <div className="form-text">Amount to be reached</div>
                </div>

                <div className="mb-3">
                  <label className="">Select Provider</label>
                  <select
                    className="form-control"
                    value={providerId}
                    onChange={(e) => setProviderId(e.target.value)}
                  >
                    <option value=""></option>
                    {
                      (appContext.supportedProviders && appContext.supportedProviders.length > 0) ?
                      appContext.supportedProviders.map((item, i) => {
                        return (
                          <option
                            key={i}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        )
                      }):""
                    }
                  </select>
                </div>
  
                <div className="mb-3">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Start Date*</label>
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                          setOpSuccessful(false);
                        }}
                      />
                    </div>
  
                    <div className="col-md-6 mb-3">
                      <label className="form-label">End Date*</label>
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                          setOpSuccessful(false);
                        }}
                      />
                    </div>
                    
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <label className="form-label">Individual TPSFs Goals</label>
                <table
                  className="table"
                >
                  <thead>
                    <tr>
                      <th>Fullname</th>
                      <th>Amount in Tsh</th>
                    </tr>
                  </thead>

                  {
                    (tpsfs && tpsfs.length > 0) ?
                    <tbody>
                      {
                        tpsfs.map((item, i) => {
                          return (
                            <tr
                              key={i}
                            >
                              <td
                                style={{
                                  textTransform: "uppercase"
                                }}
                                className="font-semi-bold text-primary"
                              >
                                {item.fullname}
                              </td>
                              <td
                              >
                                <input
                                  className="form-control"
                                  value={item.amount}
                                  type="number"
                                  onChange={(e) => {
                                    handleTpsfGoalAmountChange(i, e.target.value)
                                  }}
                                />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                    :""
                    
                  }
                </table>
              </div>

              <div className="col-md-12 text-end">
                <button
                  className="btn btn-primary btn-padded mSupportLoading"
                  onClick={() => {
                    appContext.activateDialog({
                      message: `This float goal will be created for ${branchData.fullname}, continue?`,
                      onConfirm: create,
                    })
                  }}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
  
        </div>
        
  
      </div>
    )
  } else {
    return (
      <div 
        className="container"
        
      >
        <div className="mFormType1">
          
          <h6 className="section-title">Create Float Goal For Branch</h6>
        
          <div className="body">
            <div style={{ width: "100%" }} className="mSupportLoading">
              {getInlineLoader()}
            </div>
          </div>
  
        </div>
        
  
      </div>
    )
  }
}