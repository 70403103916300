import axios from 'axios';
import Dashboard from './main-views/Dashboard';
import Branches from './main-views/Branches';
import RegisterBranch from './views/RegisterBranch';
import BranchOptions from './views/BranchOptions';
import Staff from './main-views/Staff';
import RegisterStaff from './views/RegisterStaff';
import StaffOptions from './views/StaffOptions';
import Tasks from './main-views/Tasks';
import CreateTask from './views/CreateTask';
import TaskOptions from './views/TaskOptions';
import MyTasks from './main-views/MyTasks';
import ReportTask from './views/ReportTask';
import FloatManagement from './main-views/FloatManagement';
import RequestFloat from './views/RequestFloat';
import FloatRequestOptions from './views/FloatRequestOptions';
import CashManagement from './main-views/CashManagement';
import RequestCash from './views/RequestCash';
import CashRequestOptions from './views/CashRequestOptions';
import Accounting from './main-views/Accounting';
import RecordIncome from './views/RecordIncome';
import RecordExpense from './views/RecordExpense';
import ViewTaskReport from './views/ViewTaskReport';
import CreateTpsfFloatGoal from './views/CreateTpsfFloatGoal';
import CreateBranchFloatGoal from './views/CreateBranchFloatGoal';
import BranchFloatBook from './views/BranchFloatBook';
import StaffFloatBook from './views/StaffFloatBook';
import BranchCashBook from './views/BranchCashBook';
import StaffCashBook from './views/StaffCashBook';
import UpdateStaffDetails from './views/UpdateStaffDetails';
import ChangeStaffPassword from './views/ChangeStaffPassword';
import SendFloat from './views/SendFloat';
import SendCash from './views/SendCash';
import UpdateBranchDetails from './views/UpdateBranchDetails';


export const BASE_API = '/api/';
//export const BASE_API = '/github_projects/balwyn/api/';

export function getMainView(app) {
  if(app.navItem === 'dashboard' || !app.navItem) {
    return <Dashboard/>
  } else if(app.navItem === 'branches') {
    return <Branches/>
  } else if(app.navItem === 'staff') {
    return <Staff/>
  } else if(app.navItem === 'tasks') {
    return <Tasks/>
  } else if(app.navItem === 'my_tasks') {
    return <MyTasks/>
  } else if(app.navItem === 'float_management') {
    return <FloatManagement/>
  } else if(app.navItem === 'cash_management') {
    return <CashManagement/>
  } else if(app.navItem === 'accounting') {
    return <Accounting/>
  }

  return null;
}

export function getViewerView(app) {
  if(
    app.navSubItem === 'register-branch' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RegisterBranch/>
  } else if(
    app.navSubItem === 'register-staff' &&
    (!app.navExtraItem || app.navExtraItem ) &&
    (!app.navMoreItem || app.navMoreItem)
  ) {
    return <RegisterStaff branchId={app.navExtraItem} role={app.navMoreItem}/>
  } else if(
    app.navSubItem === 'create-task' &&
    (!app.navExtraItem || app.navExtraItem ) &&
    !app.navMoreItem
  ) {
    return <CreateTask branchId={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'report-task' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ReportTask taskId={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'request-float' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RequestFloat/>
  } else if(
    app.navSubItem === 'send-float' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <SendFloat/>
  } else if(
    app.navSubItem === 'send-cash' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <SendCash/>
  } else if(
    app.navSubItem === 'request-cash' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RequestCash/>
  } else if(
    app.navSubItem === 'record-income' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RecordIncome/>
  } else if(
    app.navSubItem === 'record-expense' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RecordExpense/>
  } else if(
    app.navSubItem === 'task-report' &&
    app.navExtraItem &&
    app.navMoreItem
  ) {
    return <ViewTaskReport taskId={app.navExtraItem} selectedAgentId={app.navMoreItem}/>
  } else if(
    app.navSubItem === 'create-tpsf-float-goal' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateTpsfFloatGoal tpsfId={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'create-branch-float-goal' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateBranchFloatGoal branchId={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'branch-float-book' &&
    app.navExtraItem &&
    app.navMoreItem
  ) {
    return <BranchFloatBook branchId={app.navExtraItem} providerId={app.navMoreItem}/>
  } else if(
    app.navSubItem === 'staff-float-book' &&
    app.navExtraItem &&
    app.navMoreItem
  ) {
    return <StaffFloatBook staffId={app.navExtraItem} providerId={app.navMoreItem}/>
  } else if(
    app.navSubItem === 'branch-cash-book' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <BranchCashBook branchId={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'staff-cash-book' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <StaffCashBook staffId={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'update-staff-details' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <UpdateStaffDetails id={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'update-branch-details' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <UpdateBranchDetails id={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'change-staff-password' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ChangeStaffPassword id={app.navExtraItem}/>
  } 

  return null;
}

export function getSideViewerView(app) {
  if(
    app.navSubItem === 'branch-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <BranchOptions id={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'staff-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <StaffOptions id={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'task-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <TaskOptions id={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'float-request-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <FloatRequestOptions id={app.navExtraItem}/>
  } else if(
    app.navSubItem === 'cash-request-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CashRequestOptions id={app.navExtraItem}/>
  } 
}


export function getInlineLoader() {
  return (
    <div className="Loader"></div>
  )
}


export function formatDate(timestamp, fromPhp = true) {
  timestamp = (fromPhp) ? Number(timestamp) * 1000 : timestamp;
  let date = new Date(timestamp);
  return (date.toLocaleString());
}

export function callApi (url, params) {
  return new Promise(async resolve => {
    await getLocalUser().then(user => {
      //..
      //call main api
      let formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }

      for (let key in user) {
        formData.append(key, user[key]);
      }

      axios.post(BASE_API+url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        
        console.log(url, response.data);

        resolve(response.data);
      }).catch(error => {
        resolve({
          status: 0,
          msg: "Network Connection Error",
        })
      })
      //..
    })
    
  })
}

export function getLocalUser() {
  return new Promise(async resolve => {
    //use cookies
    let userId = getCookie('userId');
    let userToken = getCookie('userToken');
    let userType = getCookie('userType');
    
    resolve({
      userId,
      userToken,
      userType,
    })

    
  })
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function formatMoney(x) {
  //round x to 2 decimal places before og function
  x = Number(x);
  x = x.toFixed(0);

  //then continue
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function formatMoneyShorten(num, digits = 2) {
  num = Number.parseFloat(num);
  if(num <= 1 && num >= -1 ) {
    return num;
  }

  const lookup = [
   { value: 1, symbol: "" },
   { value: 1e3, symbol: "K" },
   { value: 1e6, symbol: "M" },
   { value: 1e9, symbol: "B" },
   { value: 1e12, symbol: "T" },
   { value: 1e15, symbol: "P" },
   { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return Math.abs(num) >= item.value; // Use absolute value of num
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
