import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { callApi, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import { PiStudent } from "react-icons/pi";
import { MdAddCircleOutline, MdEdit, MdSubject } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import OneFloatGoal from "../ones/OneFloatGoal";
import OneSupportedProvider from "../ones/OneSupportedProvider";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ tpsfFloatGoals, setTpsfFloatGoals ] = useState(null);
  const [ ready, setReady ] = useState(false);

  async function getAgentData() {
    await callApi(
      "get_staff_data.php",
      {
        id
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setData(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function getTpsfFloatGoals() {
    await callApi("get_tpsf_float_goals.php", { tpsfId: id }).then(response => {
      if(response.status === 1) {
        setTpsfFloatGoals(response.data);
      }
    })
  }

  async function init() {
    //run initializations here
    setReady(false);
    await getAgentData();
    await getTpsfFloatGoals();
    await appContext.getSupportedProviders();
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])
  
  if(ready && data) {
    if(
      appContext.currentUserData.role === 'admin' || 
      appContext.currentUserData.role === 'manager' ||
      appContext.currentUserData.role === 'tpsf' ||
      Number(id) === Number(appContext.currentUserData.id)
    ) {
      return (
        <div 
          className="container"
        >
          <div className="row">
  
            <div className="col-md-12">
              <h6 className="main-section-title font-semi-bold text-primary">
                {data.fullname} <br/>
                <span className="category-badge-lg bg-secondary">{data.role}</span>
              </h6>
              
            </div>
  
            

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    
                    <div className="col-6 col-md-4 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className=""
                      >
                        {data.phone || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Phone Number</h6>
                    </div>
  
                    <div className="col-6 col-md-4 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className=""
                      >
                        {data.fullname || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Full name</h6>
                    </div>
  
                    <div className="col-6 col-md-4 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className=""
                      >
                        {data.nida || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Nida Number</h6>
                    </div>
  
  
                    <div className="col-md-12">
                      <hr/>
  
                      {
                        (data.location) ?
                        <div className="row">
                          <div className="col-6 col-md-6 mb-4">
                            <h6
                              style={{
                                fontSize: "18px",
                              }}
                              className="font-semi-bold text-primary"
                            >
                              {JSON.parse(data.location)?.region?.name || "N/A"}
                            </h6>
                            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Region</h6>
                          </div>
  
                          <div className="col-6 col-md-6 mb-4">
                            <h6
                              style={{
                                fontSize: "18px",
                              }}
                              className="font-semi-bold text-primary"
                            >
                              {JSON.parse(data.location)?.district?.name || "N/A"}
                            </h6>
                            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>District</h6>
                          </div>
  
                          <div className="col-6 col-md-6 mb-4">
                            <h6
                              style={{
                                fontSize: "18px",
                              }}
                              className="font-semi-bold text-primary"
                            >
                              {JSON.parse(data.location)?.ward?.name || "N/A"}
                            </h6>
                            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Ward/Village</h6>
                          </div>
                        </div>
                        :""
                      }
  
                      {
                        (data.role === 'tpsf') ?
                        <div className="row">
                          <div className="col-md-12 mb-4">
                            <p
                              style={{
                                fontSize: "18px",
                              }}
                              className=""
                            >
                              {data.branchData?.name}
                            </p>
                            
                            <button
                              onClick={() => {
                                appContext.navTo({
                                  item: 'side-view',
                                  subItem: 'branch-options',
                                  extraItem: data.branchId
                                })
                              }}
                              className="btn btn-outline-primary btn-sm"
                            >
                              View Branch
                            </button>
                          </div>
  
                          
                        </div>
                        :""
                      }
                    </div>
  
                  </div>
                </div>
              </div>
            </div>

            {
              (
                (data.role === 'tpsf') 
                &&
                (
                  appContext.currentUserData.role === 'admin' || 
                  appContext.currentUserData.role === 'manager' ||
                  //appContext.currentUserData.role === 'tpsf' ||
                  Number(id) === Number(appContext.currentUserData.id)
                )
                
              ) ?
              
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">

                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-12">
                            <h4
                              className="font-semi-bold align-self-center"
                              style={{
                                fontSize: "18px"
                              }}
                            >
                              Books
                            </h4>
                            <hr/>
                          </div>
    
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-4 col-6 mb-2">
                                <button
                                  className="btn btn-block btn-outline-dark btn-block"
                                  onClick={() => {
                                    appContext.navTo({
                                      item: 'view',
                                      subItem: 'staff-cash-book',
                                      extraItem: data.id,
                                    })
                                  }}
                                  style={{
                                    height: "100%"
                                  }}
                                >
                                  Cash Book
                                </button>
                              </div>
                              {
                                (appContext.supportedProviders && appContext.supportedProviders.length > 0) ?
                                appContext.supportedProviders.map((item, i) => {
                                  return (
                                    <div className="col-md-4 col-6 mb-2" key={i}>
                                      <button
                                        className="btn btn-block btn-outline-dark btn-block"
                                        onClick={() => {
                                          appContext.navTo({
                                            item: 'view',
                                            subItem: 'staff-float-book',
                                            extraItem: data.id,
                                            moreItem: item.id,
                                          })
                                        }}
                                        style={{
                                          height: "100%"
                                        }}
                                      >
                                        {item.name}
                                      </button>
                                    </div>
                                  )
                                })
                                :
                                ""
                              }
                            </div>
                          </div>
                          
                        </div>
                      </div>
    
                    </div>
                  </div>
                </div>
              </div>
              
              :""
            }

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                
                    <div className="col-md-12">
                      <h6 className="font-semi-bold" style={{ fontSize: "18px" }}>Supported Providers</h6>
                      <hr/>
                      {
                        (data.supportedProviders && JSON.parse(data.supportedProviders).length > 0) ?
                        <div className="row">
                          {
                            JSON.parse(data.supportedProviders).map((item, i) => {
                              return (
                                <div
                                  className="col-12"
                                  key={i}
                                >
                                  <OneSupportedProvider data={item}/>
                                </div>
                              )
                            })
                          }
                        </div>
                        :
                        <h6>No Providers were found</h6>
                      }
                    </div>
  
                  </div>
                </div>
              </div>
            </div>

            {
              (data.role === 'tpsf') ?
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div 
                      className="d-flex justify-content-between"
                    >
                      <h6 
                        className="font-semi-bold mNoMargin align-self-center" 
                        style={{ fontSize: "18px" }}
                      >
                        Float Goals
                      </h6>

                      {/*
                        (appContext.currentUserData.role === 'admin' || appContext.currentUserData.role === 'manager') ?
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={() => {
                            appContext.navTo({
                              item: 'view',
                              subItem: 'create-tpsf-float-goal',
                              extraItem: data.id,
                            })
                          }}
                        >
                          Create New
                        </button>
                        :""
                      */}
                    </div>
                    <hr/>
                    {
                      (tpsfFloatGoals && tpsfFloatGoals.length > 0) ?
                      <div className="row">
                        {
                          tpsfFloatGoals.map((item, i) => {
                            return (
                              <div
                                className="col-12"
                                key={i}
                              >
                                <OneFloatGoal data={item}/>
                              </div>
                            )
                          })
                        }
                      </div>
                      :
                      <h6>No Float Goals were found</h6>
                    }
                  </div>
                </div>
              </div>
              :""
            }

            {
              (
                (data.role != 'agent') &&
                (
                  appContext.currentUserData.role === 'admin' || 
                  Number(data.id) === Number(appContext.currentUserData.id)
                )
              ) ?
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">

                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-12">
                            <h4
                              className="font-semi-bold align-self-center"
                              style={{
                                fontSize: "18px"
                              }}
                            >
                              Profile Settings
                            </h4>
                            <hr/>
                          </div>
    
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-4 col-6 mb-2">
                                <button
                                  className="btn btn-block btn-outline-dark btn-block"
                                  onClick={() => {
                                    appContext.navTo({
                                      item: 'view',
                                      subItem: 'update-staff-details',
                                      extraItem: data.id,
                                    })
                                  }}
                                  style={{
                                    height: "100%"
                                  }}
                                >
                                  Update Details 
                                </button>
                              </div>
                              
                              <div className="col-md-4 col-6 mb-2">
                                <button
                                  className="btn btn-block btn-outline-dark btn-block"
                                  onClick={() => {
                                    appContext.navTo({
                                      item: 'view',
                                      subItem: 'change-staff-password',
                                      extraItem: data.id,
                                    })
                                  }}
                                  style={{
                                    height: "100%"
                                  }}
                                >
                                  Change Password
                                </button>
                              </div>
                              

                            </div>
                          </div>
                          
                        </div>
                      </div>
    
                    </div>
                  </div>
                </div>
              </div>
              :""
            }
            
          </div>
        </div>
      )
    } else {
      return (
        <div 
          className="container"
        >
          <div className="row">
  
            <div className="col-md-12">
              <h6 className="main-section-title font-semi-bold text-primary">
                {data.fullname} <br/>
                <span className="category-badge-lg bg-secondary">{data.role}</span>
              </h6>
              
            </div>
  
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    
                    <div className="col-6 col-md-4 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className=""
                      >
                        {data.phone || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Phone Number</h6>
                    </div>
  
                    <div className="col-6 col-md-4 mb-4">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className=""
                      >
                        {data.fullname || "N/A"}
                      </h6>
                      <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Full name</h6>
                    </div>
  
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <hr/>

              {
                (data.location) ?
                <div className="row">
                  <div className="col-6 col-md-6 mb-4">
                    <h6
                      style={{
                        fontSize: "18px",
                      }}
                      className="font-semi-bold text-primary"
                    >
                      {JSON.parse(data.location)?.region?.name || "N/A"}
                    </h6>
                    <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Region</h6>
                  </div>

                  <div className="col-6 col-md-6 mb-4">
                    <h6
                      style={{
                        fontSize: "18px",
                      }}
                      className="font-semi-bold text-primary"
                    >
                      {JSON.parse(data.location)?.district?.name || "N/A"}
                    </h6>
                    <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>District</h6>
                  </div>

                  <div className="col-6 col-md-6 mb-4">
                    <h6
                      style={{
                        fontSize: "18px",
                      }}
                      className="font-semi-bold text-primary"
                    >
                      {JSON.parse(data.location)?.ward?.name || "N/A"}
                    </h6>
                    <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Ward/Village</h6>
                  </div>
                </div>
                :""
              }

              {
                (data.role === 'tpsf') ?
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <p
                      style={{
                        fontSize: "18px",
                      }}
                      className=""
                    >
                      {data.branchData?.name}
                    </p>
                    
                    <button
                      onClick={() => {
                        appContext.navTo({
                          item: 'side-view',
                          subItem: 'branch-options',
                          extraItem: data.branchId
                        })
                      }}
                      className="btn btn-outline-primary btn-sm"
                    >
                      View Branch
                    </button>
                  </div>

                  
                </div>
                :""
              }
            </div>
            
          </div>
        </div>
      )
    }
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}