import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import OneStaff from '../ones/OneStaff';
import { callApi } from "../Helpers";

export default function View({ role, branchId }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const[ ready, setReady ] = useState(false);

  
  const [ branchData, setBranchData ] = useState(null);
  const [ staff, setStaff ] = useState(null);


  async function init() {
    //run initializations here
    setReady(false);
    await getStaffList();
    await getBranchData();
    setReady(true);
  }

  async function getStaffList() {
    if(role === 'agent') {
      await getBranchAgents();
    } else if(role === 'tpsf') {
      await getBranchTpsfs();
    }
  }

  async function getBranchData() {
    await callApi(
      "get_branch_data.php",
      {
        id: branchId
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setBranchData(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function getBranchTpsfs() {
    await callApi(
      "get_branch_tpsfs.php",
      {
        id: branchId
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setStaff(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function getBranchAgents() {
    await callApi(
      "get_branch_agents.php",
      {
        id: branchId
      }
    ).then(response => {
      if(response.status === 1) {
        if(response.data) {
          setStaff(response.data);
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  useEffect(() => {
    init();
  }, [ ])

  
  
  if(ready && staff && branchData) {
    return (
      <div 
        className="container"
      >
        <div className="card">
          <div
            className="bg-primary"
            style={{
              width: "100%",
              padding: "20px",
              paddingTop: "calc(var(--topBarHeight) * 0.5)",
              paddingBottom: "calc(var(--topBarHeight) * 0.5)",
              height: "auto",
            }}
          >
            <div className="row">
              
              <div className="col-12">
                <h6
                  className="font-light"
                  style={{
                    fontSize: "38px",
                    marginTop: "30px",
                  }}
                >
                  {branchData.name}
                </h6>
              </div>

  
            </div>
          </div>
  
          <div className="card-body">
            <h1 className="main-section-title">
              {(role === 'agent') ? "Agents List" : ""}
              {(role === 'tpsf') ? "TPSFs List" : ""}
            </h1>
            
            {
              (staff && staff.length > 0) ?
              staff.map((item, i) => {
                return (
                  <OneStaff key={i} data={item}/>
                )
              })
              :
              <h6
                className=""
                style={{
                  fontSize: "14px",
                }}
              >
                No staff was found
              </h6>
            }
          </div>
        </div>
  
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }
}