import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ title, setTitle ] = useState("");
  const [ amount, setAmount ] = useState(0);
  const [ recordDate, setRecordDate ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  async function init() {

  }

  function resetInputFields() {
    setTitle("");
    setAmount(0);
    setRecordDate("");
  }

  async function record() {
    if(!loading) {
      if(title.trim().length > 0) {
        if(!isNaN(amount) && Number(amount) > 0) {
          if(recordDate.trim().length > 0) {
            setLoading(true);
            await callApi(
              "record_expense.php",
              {
                title,
                amount,
                recordDate,
              }
            ).then((response) => {
              if(response.status === 1) {
                appContext.getTotalExpenses();
                setOpSuccessful(true);
                appContext.tellMessage("Successful");

                resetInputFields();
              } else {
                appContext.tellError(response.msg);
              }
            })
            setLoading(false);
          } else {
            appContext.tellError("Invalid record date");
          }
        } else {
          appContext.tellError("Invalid amount");
        }
      } else {
        appContext.tellError("Invalid record title");
      }
    } else {
      appContext.tellInfo("Loading, please wait....")
    }
  }


  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  return (
    <div 
      className="container"
      
    >
      <div className="mFormType1">
        
        <h6 className="section-title">Record Expense</h6>
      
        <div className="body">
          <div className="row">
            {
              (opSuccessful) ?
              <div className="col-md-12">
                <div
                  className="bg-background text-center"
                  style={{
                    padding: "20px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <h6
                    className="font-semi-bold text-success"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    <MdCheckCircle size={60}/>
                    <br/>
                    Expense was recorded successfully
                  </h6>
                </div>
              </div>
              :""
            }

            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Record Title*</label>
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setOpSuccessful(false);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Amount in Tsh*</label>
                <input
                  type="number"
                  className="form-control"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setOpSuccessful(false);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Record Date*</label>
                <input
                  type="date"
                  className="form-control"
                  value={recordDate}
                  onChange={(e) => {
                    setRecordDate(e.target.value);
                    setOpSuccessful(false);
                  }}
                />
              </div>

            </div>

            <div className="col-md-12 text-end">
              <button
                className="btn btn-primary btn-padded mSupportLoading"
                onClick={() => {
                  appContext.activateDialog({
                    message: `This expense will be recorded, continue?`,
                    onConfirm: record,
                  })
                }}
              >
                Record
              </button>
            </div>
          </div>
        </div>

      </div>
      

    </div>
  )
}