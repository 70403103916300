import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { MdChevronRight } from "react-icons/md";
import { formatDate, formatMoney } from "../Helpers";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ data, setData ] = useState(props.data);
  
  async function init() {
    //run initializations here
    
  }

  useEffect(() => {
    init();
  }, [ data ])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ])
  
  return (
    <div 
      className="One"
      onClick={() => {
        appContext.navTo({
          item: "side-view",
          subItem: "cash-request-options",
          extraItem: data.id,
        })
      }}
    >
      <h6
        className="font-bold text-primary"
        style={{
          fontSize: "18px",
          wordWrap: "break-word", // Break long words to wrap them
        }}
      >
        {formatMoney(data.amount)} Tsh
      </h6>

      <h6
        className="font-regular text-primary mb-4"
        style={{
          fontSize: "22px",
          wordWrap: "break-word", // Break long words to wrap them
        }}
      >
        {data.providerData?.name}
      </h6>

      <div 
        className=""
        style={{
          width: "100%",
        }}
      >
        
        <p
          className="text-muted mt-2 "
          style={{
            fontSize: "14px",
            wordWrap: "break-word", // Break long words to wrap them
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal" // Allow text to wrap to the next line
          }}
        >
          <span 
            className={
              (data.status === 'pending') ?
              "category-badge-sm bg-primary":
              (data.status === 'confirmed') ?
              "category-badge-sm bg-success text-light" : "category-badge-sm bg-secondary"
            }
          >
            {data.status}
          </span>  
        </p>

        <div className="text-end" style={{ fontSize: "12px" }}>
          Requested On <i>{formatDate(data.requestDate).substring(0, 10)}</i> 
        </div>
        
      </div>

    </div>
  )
}