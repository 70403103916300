import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle, MdWarning } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ amount, setAmount ] = useState(0);
  const [ providerId, setProviderId ] = useState("");
  const [ requestForAgent, setRequestForAgent ] = useState("no");
  const [ receiverPhone, setReceiverPhone ] = useState("");
  const [ receiverData, setReceiverData ] = useState(null);
  const [ isReceiverDataLoading, setIsReceiverDataLoading ] = useState(false);

  
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getSupportedProviders();
    setReady(true);
  }

  function resetFields() {
    setAmount(0);
    setProviderId("");
    setReceiverPhone("");
    setRequestForAgent("no");
    setOpSuccessful(false);
  }

  async function getReceiverData() {
    setIsReceiverDataLoading(true);
    await callApi("get_staff_data_by_phone.php", { phone: receiverPhone }).then(response => {
      if(response.status === 1) {
        setReceiverData(response.data);
      } else {
        setReceiverData(null);
      }
    })
    setIsReceiverDataLoading(false);
  }

  async function sendRequest() {
    if(!loading) {
      if(!isNaN(amount) && Number(amount) > 0) {
        if(String(providerId).trim().length > 0) {
          if(requestForAgent === 'no' || (requestForAgent === 'yes' && receiverData && receiverData.role === 'agent')) {
            //..
            setLoading(true);
            await callApi(
              'send_float_request.php',
              {
                amount,
                providerId,
                requestAs: appContext.currentUserData.role,
                branchId: appContext.currentUserData.branchId,
                requestForAgent,
                agentId: (receiverData) ? receiverData.id : "",
              }
            ).then((response) => {
              if(response.status === 1) {
                setOpSuccessful(true);
                appContext.getMyFloatRequests();
                appContext.tellMessage("Request sent!");
              } else {
                appContext.tellError(response.msg);
              }
            });
            setLoading(false);
            //..
          } else {
            appContext.tellError("Invalid Agent's phone")
          }

          
        } else {
          appContext.tellError("Invalid provider selection");
        }
      } else {
        appContext.tellError("Invalid amount");
      }
    } else {
      appContext.tellWarning("Loading, Please wait....")
    }
  }

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    getReceiverData();
  }, [ receiverPhone ])

  
  if(ready) {
    if(appContext.currentUserData.role === 'agent' || appContext.currentUserData.role === 'tpsf') {
      return (
        <div 
          className="container"
          
        >
          <div className="mFormType1">
            
            <h6 className="section-title">Request Float</h6>
          
            <div className="body">
              <div className="row">
                {
                  (opSuccessful) ?
                  <div className="col-md-12">
                    <div
                      className="bg-background text-center"
                      style={{
                        padding: "20px",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <h6
                        className="font-semi-bold text-success"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <MdCheckCircle size={60}/>
                        <br/>
                        Request was sent successfully

                        <hr/>

                        <button
                          className="btn btn-padded btn-secondary"
                          onClick={resetFields}
                        >
                          Request Again
                        </button>
                      </h6>
                    </div>
                  </div>
                  :
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="">Requesting As</label>
                      <h6
                        className="font-regular"
                        style={{
                          fontSize: "24px",
                          textTransform: "capitalize",
                        }}
                      >
                        {appContext.currentUserData.role}
                      </h6>
                    </div>

                    {
                      (appContext.currentUserData.role === 'tpsf' || appContext.currentUserData.role === 'agent') ?
                      <div className="mb-3">
                        <label className="">Branch</label>
                        <h6
                          className="font-regular"
                          style={{
                            fontSize: "24px",
                            textTransform: "capitalize",
                          }}
                        >
                          {appContext.currentUserData.branchData.name}
                        </h6>
                      </div>
                      :""
                    }

                    <div className="mb-3">
                      <label className="">Select Provider</label>
                      <select
                        className="form-control"
                        value={providerId}
                        onChange={(e) => setProviderId(e.target.value)}
                      >
                        <option value=""></option>
                        {
                          (appContext.supportedProviders && appContext.supportedProviders.length > 0) ?
                          appContext.supportedProviders.map((item, i) => {
                            return (
                              <option
                                key={i}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            )
                          }):""
                        }
                      </select>
                    </div>

                    <div className="mb-3">
                      <label className="">Request directly for Agent?</label>
                      <select
                        className="form-control"
                        value={requestForAgent}
                        onChange={(e) => setRequestForAgent(e.target.value)}
                      >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </select>
                    </div>

                    {
                      (requestForAgent === 'yes') ?
                      <div className="mb-3">
                        <label>Agent's Phone</label>
                        <input
                          className="form-control"
                          value={receiverPhone}
                          maxLength={10}
                          onChange={(e) => setReceiverPhone(e.target.value)}
                        />
                        <div className="form-text">Phone number of a registered agent</div>

                        <div 
                          className="mSupportLoading" 
                          style={{ 
                            width: "100%",
                            borderLeft: "5px solid var(--primaryColor)",
                            padding: "10px"
                          }}
                        >
                          {
                            (isReceiverDataLoading) ?
                            getInlineLoader()
                            :
                            (receiverData) ?
                            <>
                              <h6
                                style={{
                                  fontSize: "18px",
                                }}
                                className="font-regular"
                              >
                                {receiverData.fullname}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    display: "block",
                                  }}
                                  className="font-semi-bold"
                                >
                                  {receiverData.role}
                                </span>
                              </h6>
                            </>
                            :""
                          }
                        </div>
                      </div>
                      :""
                    }

                    <div className="mb-3">
                      <label>Amount</label>
                      <input
                        className="form-control"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>

                    <div className="mb-3 text-end">
                      <button
                        className="btn btn-padded mSupportLoading btn-primary"
                        onClick={() => {
                          appContext.activateDialog({
                            message: "Are you sure you want to send float request?",
                            onConfirm: sendRequest,
                          })
                        }}
                      >
                        {(loading) ? getInlineLoader() : "Send Request"}
                      </button>
                    </div>
                    
                  </div>
                }
    
                

                
              </div>
            </div>
    
          </div>
          
    
        </div>
      )
    } else {
      return (
        <div 
          className="container"
          
        >
          <div className="mFormType1">
            
            <h6 className="section-title">Request Float</h6>
          
            <div className="body">
              <div className="row">
                
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div
                      className="bg-background text-center"
                      style={{
                        padding: "20px",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <h6
                        className="font-semi-bold text-danger"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <MdWarning size={60}/>
                        <br/>
                        Only TPSFs and Agents can make float requests
                      </h6>
                    </div>
                  </div>
                </div>

              </div>
            </div>
    
          </div>
          
    
        </div>
      )
    }
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}