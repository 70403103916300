import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import OneStaff from '../ones/OneStaff';
import { MdSearch } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const[ ready, setReady ] = useState(false);

  const [ staffCount, setStaffCount ] = useState(0);
  const [ agentsCount, setAgentsCount ] = useState(0);
  const [ tpsfCount, setTpcfCount ] = useState(0);
  const [ filter, setFilter ] = useState("");


  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getAllStaff();
    setReady(true);
  }


  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(appContext.allStaff) {
      setStaffCount(appContext.allStaff.length);

      //count agents and tpsf
      let _tpsfCount = 0;
      let _agentsCount = 0;

      for (const _staff of appContext.allStaff) {
        if(_staff.role === 'agent') {
          _agentsCount += 1;
        }

        if(_staff.role === 'tpsf') {
          _tpsfCount += 1;
        }
      }

      setAgentsCount(_agentsCount);
      setTpcfCount(_tpsfCount);
    }
  }, [ appContext.allStaff ])
  
  if(ready) {
    return (
      <div 
        className="container"
      >
        <div className="card">
          <div
            className="bg-primary"
            style={{
              width: "100%",
              padding: "20px",
              paddingTop: "calc(var(--topBarHeight) * 0.5)",
              paddingBottom: "calc(var(--topBarHeight) * 0.5)",
              height: "auto",
            }}
          >
            <div className="row">
              
              <div className="col-md-4 col-12">
                <h6
                  className="font-light"
                  style={{
                    fontSize: "48px",
                    marginTop: "30px",
                  }}
                >
                  {staffCount}
                </h6>
                <h6 className="font-semi-bold" style={{ fontSize: "14px" }}>All Staff</h6>
              </div>

              <div className="col-md-4 col-6">
                <h6
                  className="font-light"
                  style={{
                    fontSize: "48px",
                    marginTop: "30px",
                  }}
                >
                  {agentsCount}
                </h6>
                <h6 className="font-semi-bold" style={{ fontSize: "14px" }}>Agents</h6>
              </div>

              <div className="col-md-4 col-6">
                <h6
                  className="font-light"
                  style={{
                    fontSize: "48px",
                    marginTop: "30px",
                  }}
                >
                  {tpsfCount}
                </h6>
                <h6 className="font-semi-bold" style={{ fontSize: "14px" }}>TPSF</h6>
              </div>

  
            </div>
          </div>
  
          <div className="card-body">
            <h1 className="main-section-title">Staff</h1>

            <div className="text-end mb-3">
              <button
                className="btn btn-padded btn-outline-primary"
                onClick={() => {
                  appContext.navTo({
                    item: 'view',
                    subItem: 'register-staff'
                  })
                }}
              >
                Register Staff
              </button>
            </div>

            <div 
              className="d-flex"
              
            >
              <MdSearch size={30} className="align-self-center"/>
              <input
                className="form-control"
                style={{
                  width: "250px",
                }}
                placeholder="filter by name or phone"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <hr/>
            
            
            {
              (appContext.allStaff && appContext.allStaff.length > 0) ?
              appContext.allStaff.map((item, i) => {
                if(filter && filter.trim().length > 0) {
                  if(
                    String(item.phone).toUpperCase().indexOf(String(filter).toUpperCase()) > -1 ||
                    String(item.fullname).toUpperCase().indexOf(String(filter).toUpperCase()) > -1
                  ) {
                    return (
                      <OneStaff key={i} data={item}/>
                    )
                  } else {
                    return "";
                  }
                } else {
                  return (
                    <OneStaff key={i} data={item}/>
                  )
                }
                
              })
              :
              <h6
                className=""
                style={{
                  fontSize: "14px",
                }}
              >
                No staff was found
              </h6>
            }
          </div>
        </div>
  
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }
}