import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle, MdWarning } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ taskId, setTaskId ] = useState(props.taskId);
  const [ selectedAgentId, setSelectedAgentId ] = useState(props.selectedAgentId);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ taskData, setTaskData ] = useState(null);
  const [ selectedAgentData, setSelectedAgentData ] = useState(null);
  const [ selectedEntry, setSelectedEntry ] = useState(null);

  const [ operatorName, setOperatorName ] = useState("");
  const [ operatorPhone, setOperatorPhone ] = useState("");
  const [ tillNumber, setTillNumber ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ accountNumber, setAccountNumber ] = useState("");
  const [ deviceSerialNumber, setDeviceSerialNumber ] = useState("");
  const [ deviceImei1, setDeviceImei1 ] = useState("");
  const [ deviceImei2, setDeviceImei2 ] = useState("");
  const [ deviceType, setDeviceType ] = useState("");
  
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await getTaskData();
    await getSelectedAgentData();
    setReady(true);
  }

  async function getTaskData() {
    await callApi("get_task_data.php", { id: taskId }).then(response => {
      if(response.status === 1) {
        setTaskData(response.data);
      }
    })
  }

  async function getSelectedAgentData() {
    await callApi('get_staff_data.php', { id: selectedAgentId }).then(response => {
      if(response.status === 1) {
        setSelectedAgentData(response.data);
      } 
    })
  }

  
  useEffect(() => {
    init();
  }, [ taskId, selectedAgentId ])

  useEffect(() => {
    setTaskId(props.taskId);
  }, [ props.taskId ])

  useEffect(() => {
    setSelectedAgentId(props.selectedAgentId);
  }, [ props.selectedAgentId ])

  useEffect(() => {
    if(taskData) {
      //get selected entry
      const _list = JSON.parse(taskData.agentsList);

      for (const _item of _list) {
        if(Number(_item.id) === Number(selectedAgentId)) {
          setSelectedEntry(_item);
          break;
        }
      }
    }
  }, [ taskData ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  if(ready && taskData && selectedAgentData && selectedEntry) {
    return (
      <div 
        className="container"
        
      >
        <div className="mFormType1" style={{ maxWidth: "900px" }}>
          
          <h6 className="section-title">Task Report</h6>

          <div className="body">
            <div className="row">
          
              <div className="col-md-12">
              
                <div className="mb-4">
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px"
                    }}
                  >
                    Task Title
                  </h6>

                  <h6
                    style={{
                      fontSize: "24px"
                    }}
                    className="font-semi-bold"
                  >
                    {taskData.title}
                  </h6>
                </div>

                {
                  (taskData.taskType === 'float') ?
                  <>
                    <div className="mb-4">
                      <h6
                        className="font-regular"
                        style={{
                          fontSize: "16px"
                        }}
                      >
                        Float Target
                      </h6>

                      <h6
                        style={{
                          fontSize: "24px"
                        }}
                        className="font-semi-bold"
                      >
                        {formatMoney(taskData.floatTarget)}
                      </h6>
                    </div>

                    <div className="mb-4">
                      <h6
                        className="font-regular"
                        style={{
                          fontSize: "16px"
                        }}
                      >
                        Provider
                      </h6>

                      <h6
                        style={{
                          fontSize: "24px"
                        }}
                        className="font-semi-bold"
                      >
                        {taskData.providerData.name}
                      </h6>
                    </div>

                    <div className="mb-4">
                      <h6
                        className="font-regular"
                        style={{
                          fontSize: "16px"
                        }}
                      >
                        Float Served
                      </h6>

                      <h6
                        style={{
                          fontSize: "24px"
                        }}
                        className="font-semi-bold"
                      >
                        {formatMoney(selectedEntry.floatServed)}
                      </h6>
                    </div>
                  </>
                  :""
                }

                <div className="mb-4">
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px"
                    }}
                  >
                    Agent's Name
                  </h6>

                  <h6
                    style={{
                      fontSize: "24px"
                    }}
                    className="font-semi-bold"
                  >
                    {selectedEntry.fullname}
                  </h6>
                </div>

                <div className="mb-4">
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px"
                    }}
                  >
                    Agent's Location
                  </h6>

                  <h6
                    style={{
                      fontSize: "14px"
                    }}
                    className="font-semi-bold"
                  >
                    <span style={{ display: "block" }}>Region: {(selectedEntry.report?.location?.region?.name) || "N/A"}</span>
                    <span style={{ display: "block" }}>District: {(selectedEntry.report?.location?.district?.name) || "N/A"}</span>
                    <span style={{ display: "block" }}>Ward/Village: {(selectedEntry.report?.location?.ward?.name) || "N/A"}</span> 
                  </h6>
                </div>

                <div className="mb-4">
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px"
                    }}
                  >
                    Attended On
                  </h6>

                  <h6
                    style={{
                      fontSize: "14px"
                    }}
                    className="font-semi-bold"
                  >
                    {formatDate(selectedEntry.completionDate)}
                  </h6>
                </div>

                <hr/>
              </div>

              {
                (selectedEntry.report) ?
                //..
                <div className="col-md-12">
                  <div className="row">

                    <div className="col-md-12 mb-4">
                      
                      <h3
                        style={{
                          fontSize: "18px",
                        }}
                        className="mNoMargin"
                      >
                        Was task completed? <span className="font-bold">{selectedEntry.report?.completed}</span>
                      </h3>
                      
                      {
                        (selectedEntry.report?.completed === 'no') ?
                        <div style={{ width: "100%", borderLeft: "4px solid var(--primaryColor)", padding: "10px" }}>
                          <label className="form-label text-muted">Reason/Cause</label>
                          <h6 style={{ fontSize: "14px" }}>{selectedEntry.report?.excuse}</h6>
                        </div>
                        :""
                      }
                    </div>

                    <div className="col-md-4 mb-2">
                      
                      <h3
                        style={{
                          fontSize: "28px",
                        }}
                        className="mNoMargin"
                      >
                        {selectedEntry.report?.operatorName || "N/A"}
                      </h3>
                      <label className="form-label text-muted">Operator's name</label>
                    </div>

                    <div className="col-md-4 mb-2">
                      <h3
                        style={{
                          fontSize: "28px",
                        }}
                        className="mNoMargin"
                      >
                        {selectedEntry.report?.operatorPhone || "N/A"}
                      </h3>
                      <label className="form-label text-muted">Operator's phone</label>
                    </div>

                    <div className="col-md-4 mb-2">
                      
                      <h3
                        style={{
                          fontSize: "28px",
                        }}
                        className="mNoMargin"
                      >
                        {selectedEntry.report?.tillNumber || "N/A"}
                      </h3>
                      <label className="form-label text-muted">Till Number</label>
                    </div>

                    <div className="col-md-4 mb-2">
                      <h3
                        style={{
                          fontSize: "28px",
                        }}
                        className="mNoMargin"
                      >
                        {selectedEntry.report?.accountNumber || "N/A"}
                      </h3>
                      <label className="form-label text-muted">Bank Account Number</label>
                    </div>
                    

                    <div className="col-md-4 mb-2">
                      <h3
                        style={{
                          fontSize: "28px",
                        }}
                        className="mNoMargin"
                      >
                        {selectedEntry.report?.deviceSerialNumber || "N/A"}
                      </h3>
                      <label className="form-label text-muted">Serial Number</label>
                    </div>

                    <div className="col-md-4 mb-2">
                      <h3
                        style={{
                          fontSize: "28px",
                        }}
                        className="mNoMargin"
                      >
                        {selectedEntry.report?.deviceType || "N/A"}
                      </h3>
                      <label className="form-label text-muted">Device Type</label>
                    </div>

                    <div className="col-md-4 mb-2">
                      <h3
                        style={{
                          fontSize: "28px",
                        }}
                        className="mNoMargin"
                      >
                        {selectedEntry.report?.deviceImei1 || "N/A"}
                      </h3>
                      <label className="form-label text-muted">Imei 1</label>
                    </div>

                    <div className="col-md-4 mb-2">
                      <h3
                        style={{
                          fontSize: "28px",
                        }}
                        className="mNoMargin"
                      >
                        {selectedEntry.report?.deviceImei2 || "N/A"}
                      </h3>
                      <label className="form-label text-muted">Imei 2</label>
                    </div>

                    <div className="col-md-12 mb-2">
                      <hr/>
                      <label className="form-label text-muted">Description</label>
                      <h3
                        style={{
                          fontSize: "22px",
                        }}
                        className="mNoMargin"
                      >
                        {selectedEntry.report?.description || "N/A"}
                      </h3>
                      
                    </div>

                    
                    
                  </div>
                </div>
                :
                ""

              }
              
            </div>
          </div>
        </div>
      
      </div>
    )
    
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}