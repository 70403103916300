import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { MdChevronRight } from "react-icons/md";
import { formatDate, formatMoney } from "../Helpers";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ data, setData ] = useState(props.data);

  async function init() {
    //run initializations here
  }

  useEffect(() => {
    init();
  }, [ data ])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ])
  
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">

          <div className="col-6 col-md-4 mb-3">
            <h6
              style={{
                fontSize: "18px",
              }}
              className=""
            >
              {data.name || "N/A"}
            </h6>
            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Provider</h6>
          </div>

          <div className="col-6 col-md-4 mb-3">
            <h6
              style={{
                fontSize: "18px",
              }}
              className=""
            >
              {data.tillNumber || "N/A"}
            </h6>
            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Till Number</h6>
          </div>

          <div className="col-6 col-md-4 mb-3">
            <h6
              style={{
                fontSize: "18px",
              }}
              className=""
            >
              {data.providerType || "N/A"}
            </h6>
            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Provider Type</h6>
          </div>

          {
            (data.providerType === 'bank') ?
            <div 
              className="col-12"
              
            >
              <div
                style={{
                  borderLeft: "4px solid var(--primaryColor)",
                  paddingLeft: "10px",
                  marginTop: "20px",
                }}
              >

                <div className="mb-2">
                  <h6
                    style={{
                      fontSize: "16px",
                    }}
                    className="mNoMargin"
                  >
                    {data.accountNumber || "N/A"}
                  </h6>
                  <h6 className="font-semi-bold text-muted mNoMargin" style={{ fontSize: "12px" }}>Account Number</h6>
                </div>

                <div className="mb-2">
                  <h6
                    style={{
                      fontSize: "16px",
                    }}
                    className="mNoMargin"
                  >
                    {data.deviceType || "N/A"}
                  </h6>
                  <h6 className="font-semi-bold text-muted mNoMargin" style={{ fontSize: "12px" }}>Device Type</h6>
                </div>

                <div className="mb-2">
                  <h6
                    style={{
                      fontSize: "16px",
                    }}
                    className="mNoMargin"
                  >
                    {data.deviceSerialNumber || "N/A"}
                  </h6>
                  <h6 className="font-semi-bold text-muted mNoMargin" style={{ fontSize: "12px" }}>Device Serial Number</h6>
                </div>

                <div className="mb-2">
                  <h6
                    style={{
                      fontSize: "16px",
                    }}
                    className="mNoMargin"
                  >
                    {data.deviceImei1 || "N/A"}
                  </h6>
                  <h6 className="font-semi-bold text-muted mNoMargin" style={{ fontSize: "12px" }}>Device Imei1</h6>
                </div>

                <div className="mb-2">
                  <h6
                    style={{
                      fontSize: "16px",
                    }}
                    className="mNoMargin"
                  >
                    {data.deviceImei2 || "N/A"}
                  </h6>
                  <h6 className="font-semi-bold text-muted mNoMargin" style={{ fontSize: "12px" }}>Device Imei2</h6>
                </div>
              </div>
            </div>
            :""
          }

        </div>
      </div>
    </div>
  )
}

