import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import OneTask from '../ones/OneTask';
import { MdSearch } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const[ ready, setReady ] = useState(false);

  const [ allCount, setAllCount ] = useState(0);
  const [ pendingCount, setPendingCount ] = useState(0);
  const [ completedCount, setCompletedCount ] = useState(0);
  const [ expiredCount, setExpiredCount ] = useState(0);
  const [ filter, setFilter ] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    if(appContext.currentUserData.role === 'admin') {
      await appContext.getAllTasks();
    } else if(appContext.currentUserData.role === 'manager') {
      await appContext.getBranchTasks();
    }
    setReady(true);
  }


  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(appContext.currentUserData.role === 'admin') {
      if(appContext.allTasks) {
        setAllCount(appContext.allTasks.length);
  
        //count agents and tpsf
        let _pendingCount = 0;
        let _expiredCount = 0;
        let _completedCount = 0;
        
  
        for (const _task of appContext.allTasks) {
          if(_task.status === 'pending') {
            _pendingCount += 1;
          }
  
          if(_task.status === 'expired') {
            _expiredCount += 1;
          }
  
          if(_task.status === 'completed') {
            _completedCount += 1;
          }
        }
  
        setPendingCount(_pendingCount);
        setExpiredCount(_expiredCount);
        setCompletedCount(_completedCount);
  
      }
    } else if(appContext.currentUserData.role === 'manager') {
      if(appContext.branchTasks) {
        setAllCount(appContext.branchTasks.length);
  
        //count agents and tpsf
        let _pendingCount = 0;
        let _expiredCount = 0;
        let _completedCount = 0;
        
  
        for (const _task of appContext.branchTasks) {
          if(_task.status === 'pending') {
            _pendingCount += 1;
          }
  
          if(_task.status === 'expired') {
            _expiredCount += 1;
          }
  
          if(_task.status === 'completed') {
            _completedCount += 1;
          }
        }
  
        setPendingCount(_pendingCount);
        setExpiredCount(_expiredCount);
        setCompletedCount(_completedCount);
  
      }
    }
  }, [ appContext.allTasks, appContext.branchTasks ])
  
  if(ready) {
    if(appContext.currentUserData.role === 'admin') {
      return (
        <div 
          className="container"
        >
          <div className="card">
            <div
              className="bg-primary"
              style={{
                width: "100%",
                padding: "20px",
                paddingTop: "calc(var(--topBarHeight) * 0.5)",
                paddingBottom: "calc(var(--topBarHeight) * 0.5)",
                height: "auto",
              }}
            >
              <div className="row">
                
                <div className="col-md-12 col-12">
                  <h6
                    className="font-light"
                    style={{
                      fontSize: "48px",
                      marginTop: "30px",
                    }}
                  >
                    {allCount}
                  </h6>
                  <h6 className="font-semi-bold" style={{ fontSize: "16px" }}>All Tasks</h6>
                </div>
  
                <div className="col-md-4 col-4">
                  <h6
                    className="font-light"
                    style={{
                      fontSize: "32px",
                      marginTop: "30px",
                    }}
                  >
                    {completedCount}
                  </h6>
                  <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Completed</h6>
                </div>
  
                <div className="col-md-4 col-4">
                  <h6
                    className="font-light"
                    style={{
                      fontSize: "32px",
                      marginTop: "30px",
                    }}
                  >
                    {pendingCount}
                  </h6>
                  <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Pending</h6>
                </div>
  
                <div className="col-md-4 col-4">
                  <h6
                    className="font-light"
                    style={{
                      fontSize: "32px",
                      marginTop: "30px",
                    }}
                  >
                    {expiredCount}
                  </h6>
                  <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Expired</h6>
                </div>
    
              </div>
            </div>
    
            <div className="card-body">
              <h1 className="main-section-title">Tasks</h1>
  
              <div className="text-end mb-3">
                <button
                  className="btn btn-padded btn-outline-primary"
                  onClick={() => {
                    appContext.navTo({
                      item: 'view',
                      subItem: 'create-task'
                    })
                  }}
                >
                  Create Task
                </button>
              </div>

              <div 
                className="d-flex"
                
              >
                <MdSearch size={30} className="align-self-center"/>
                <input
                  className="form-control"
                  style={{
                    width: "250px",
                  }}
                  placeholder="filter by title"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>
  
              <hr/>
              
              {
                (appContext.allTasks && appContext.allTasks.length > 0) ?
                appContext.allTasks.map((item, i) => {
                  if(filter && filter.trim().length > 0) {
                    if(
                      String(item.title).toUpperCase().indexOf(String(filter).toUpperCase()) > -1
                    ) {
                      return (
                        <OneTask key={i} data={item}/>
                      )
                    } else {
                      return "";
                    }
                  } else {
                    return (
                      <OneTask key={i} data={item}/>
                    )
                  }
                })
                :
                <h6
                  className=""
                  style={{
                    fontSize: "14px",
                  }}
                >
                  No task was found
                </h6>
              }
            </div>
          </div>
    
        </div>
      )
    } else if (appContext.currentUserData.role === 'manager') {
      return (
        <div 
          className="container"
        >
          <div className="card">
            <div
              className="bg-primary"
              style={{
                width: "100%",
                padding: "20px",
                paddingTop: "calc(var(--topBarHeight) * 0.5)",
                paddingBottom: "calc(var(--topBarHeight) * 0.5)",
                height: "auto",
              }}
            >
              <div className="row">
                
                <div className="col-md-12 col-12">
                  <h6
                    className="font-light"
                    style={{
                      fontSize: "48px",
                      marginTop: "30px",
                    }}
                  >
                    {allCount}
                  </h6>
                  <h6 className="font-semi-bold" style={{ fontSize: "16px" }}>Branch's Tasks</h6>
                </div>
  
                <div className="col-md-4 col-4">
                  <h6
                    className="font-light"
                    style={{
                      fontSize: "32px",
                      marginTop: "30px",
                    }}
                  >
                    {completedCount}
                  </h6>
                  <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Completed</h6>
                </div>
  
                <div className="col-md-4 col-4">
                  <h6
                    className="font-light"
                    style={{
                      fontSize: "32px",
                      marginTop: "30px",
                    }}
                  >
                    {pendingCount}
                  </h6>
                  <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Pending</h6>
                </div>
  
                <div className="col-md-4 col-4">
                  <h6
                    className="font-light"
                    style={{
                      fontSize: "32px",
                      marginTop: "30px",
                    }}
                  >
                    {expiredCount}
                  </h6>
                  <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Expired</h6>
                </div>
    
              </div>
            </div>
    
            <div className="card-body">
              <h1 className="main-section-title">Tasks</h1>
  
              <div className="text-end mb-3">
                <button
                  className="btn btn-padded btn-outline-primary"
                  onClick={() => {
                    appContext.navTo({
                      item: 'view',
                      subItem: 'create-task',
                      extraItem: appContext.currentUserData.branchId,
                    })
                  }}
                >
                  Create Task
                </button>
              </div>


              <div 
                className="d-flex"
                
              >
                <MdSearch size={30} className="align-self-center"/>
                <input
                  className="form-control"
                  style={{
                    width: "250px",
                  }}
                  placeholder="filter by title"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>

              <hr/>
              
              {
                (appContext.branchTasks && appContext.branchTasks.length > 0) ?
                appContext.branchTasks.map((item, i) => {
                  
                  if(filter && filter.trim().length > 0) {
                    if(
                      String(item.title).toUpperCase().indexOf(String(filter).toUpperCase()) > -1
                    ) {
                      return (
                        <OneTask key={i} data={item}/>
                      )
                    } else {
                      return "";
                    }
                  } else {
                    return (
                      <OneTask key={i} data={item}/>
                    )
                  }
                })
                :
                <h6
                  className=""
                  style={{
                    fontSize: "14px",
                  }}
                >
                  No task was found
                </h6>
              }
            </div>
          </div>
    
        </div>
      )
    }
  } else {
    return (
      <div 
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }
}