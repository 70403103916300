import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle, MdWarning } from "react-icons/md";
import { MdSearch } from "react-icons/md";


export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ taskId, setTaskId ] = useState(props.taskId);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ taskData, setTaskData ] = useState(null);
  const [ selectedAgentIndex, setSelectedAgentIndex ] = useState(null); 
  const [ selectedAgentData, setSelectedAgentData ] = useState(null);

  const [ operatorName, setOperatorName ] = useState("");
  const [ operatorPhone, setOperatorPhone ] = useState("");
  const [ tillNumber, setTillNumber ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ accountNumber, setAccountNumber ] = useState("");
  const [ deviceSerialNumber, setDeviceSerialNumber ] = useState("");
  const [ deviceImei1, setDeviceImei1 ] = useState("");
  const [ deviceImei2, setDeviceImei2 ] = useState("");
  const [ deviceType, setDeviceType ] = useState("");
  const [ completed, setCompleted ] = useState("yes");
  const [ excuse, setExcuse ] = useState("");

  const [ filter, setFilter ] = useState("");

  
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await getTaskData();
    setReady(true);
  }

  async function getTaskData() {
    await callApi("get_task_data.php", { id: taskId }).then(response => {
      if(response.status === 1) {
        setTaskData(response.data);
      }
    })
  }

  async function createReport(_agentIndex, _agentId) {
    setLoading(true);
    //get agent data
    await callApi('get_staff_data.php', { id: _agentId }).then(response => {
      if(response.status === 1) {
        setSelectedAgentData(response.data);
        setSelectedAgentIndex(_agentIndex)
      } else {
        appContext.tellError("Cannot get Agent's Data");
      }
    })
    setLoading(false);
  }

  function closeReportCreator() {
    setSelectedAgentData(null);
    setSelectedAgentIndex(null);
  }

  async function completeTaskForAgent(agentId) {
    
    if(selectedAgentData) {
      //..
      if(operatorName.trim().length > 0) {
        if(operatorPhone.trim().length > 0) {
          //create report object
          const report = {
            operatorName,
            operatorPhone,
            tillNumber,
            description,
            accountNumber,
            deviceType,
            deviceSerialNumber,
            deviceImei1,
            deviceImei2,
            location: JSON.parse(selectedAgentData.location),
            completed,
            excuse,
          }
  
          //then send it
          setLoading(true);
          await callApi("complete_task_for_agent.php", { taskId, agentId: selectedAgentData.id, report: JSON.stringify(report) }).then(async response => {
            if(response.status === 1) {
              await getTaskData();
              closeReportCreator();
              setOpSuccessful(true);
              appContext.getAllTasks();
              appContext.getMyTasks();
              setLoading(false);
              appContext.tellMessage("Report was saved");
            } else {
              appContext.tellError(response.msg);
              setLoading(false);
            }
          })
  
        } else {
          appContext.tellError("Invalid operator's phone");
        }
      } else {
        appContext.tellError("Invalid operator's name");
      }
      //..
    } else {
      appContext.tellError("Agent not selected");
    }
    
  }


  useEffect(() => {
    init();
  }, [ taskId ])

  useEffect(() => {
    setTaskId(props.taskId);
  }, [ props.taskId ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  if(ready && taskData) {
    if(appContext.doesCurrentUserOwnTask(taskId)) {
      return (
        <div 
          className="container"
          
        >
          <div className="mFormType1" style={{ maxWidth: "900px" }}>
            
            <h6 className="section-title">Report Task</h6>

            {
              (selectedAgentIndex === null) ?
              //..
              <div className="body">
                <div className="row">
                  {
                    (opSuccessful) ?
                    <div className="col-md-12">
                      <div
                        className="bg-background text-center"
                        style={{
                          padding: "20px",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      >
                        <h6
                          className="font-semi-bold text-success"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          <MdCheckCircle size={60}/>
                          <br/>
                          Task was reported successfully
                        </h6>
                      </div>
                    </div>
                    :""
                  }
      
                  <div className="col-md-12">
                    
                    <div className="mb-4">
                      <h6
                        className="font-regular"
                        style={{
                          fontSize: "16px"
                        }}
                      >
                        Title
                      </h6>

                      <h6
                        style={{
                          fontSize: "24px"
                        }}
                        className="font-semi-bold"
                      >
                        {taskData.title}
                      </h6>
                    </div>

                    <div className="mb-4">
                      <h6
                        className="font-regular"
                        style={{
                          fontSize: "16px"
                        }}
                      >
                        Pending Agents
                      </h6>

                      <div 
                        className="d-flex"
                        
                      >
                        <MdSearch size={30} className="align-self-center"/>
                        <input
                          className="form-control"
                          style={{
                            width: "250px",
                          }}
                          placeholder="filter by name or phone"
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                        />
                      </div>

                      <table
                        className="table table-striped"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        <thead>
                          <tr>
                            <th style={{ fontSize: "12px" }} className="font-bold">
                              Agent
                            </th>
                            <th style={{ fontSize: "12px" }} className="font-bold">
                              Phone
                            </th>
                            <th style={{ fontSize: "12px" }} className="font-bold">
                              Action
                            </th> 
                          </tr>
                        </thead>

                        <tbody>
                          {
                            (taskData && taskData.agentsList && JSON.parse(taskData.agentsList).length > 0) ?
                            JSON.parse(taskData.agentsList).map((item, i) => {
                              if(!item.completed) {
                                const _render = (
                                  <tr
                                    key={i}
                                  >
                                    <td
                                    >
                                      <span
                                        className="font-bold text-primary"
                                        onClick={() => {
                                          appContext.navTo({
                                            item: 'side-view',
                                            subItem: 'staff-options',
                                            extraItem: Number(item.id)
                                          })
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.fullname}
                                      </span>
                                    </td>
                                    <td
                                    >
                                      <span
                                        className="font-bold text-primary"
                                        onClick={() => {
                                          appContext.navTo({
                                            item: 'side-view',
                                            subItem: 'staff-options',
                                            extraItem: Number(item.id)
                                          })
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.phone}
                                      </span>
                                    </td>
                                    <td
                                    >
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={() => {
                                          setOpSuccessful(false);
                                          createReport(i, item.id);
                                        }}
                                      >
                                        Create Report
                                      </button>
                                    </td>
                                    
                                  </tr>
                                );

                                //implement filter
                                if(filter && filter.trim().length > 0) {
                                  if(
                                    String(item.phone).toUpperCase().indexOf(String(filter).toUpperCase()) > -1 ||
                                    String(item.fullname).toUpperCase().indexOf(String(filter).toUpperCase()) > -1
                                  ) {
                                    return _render;
                                  } else {
                                    return null;
                                  }
                                } else {
                                  return _render;
                                }
                                
                              }
                            })
                            :null
                          }
                        </tbody>
                      </table>
                      
                    </div>


                  </div>

                </div>
              </div>
              :
              <div className="body">
                <div className="row">
                  
                  <div className="col-md-12">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={closeReportCreator}
                    >
                      View Pending Agents' List
                    </button>
                    <hr/>

                  </div>

                  {
                    (opSuccessful) ?
                    <div className="col-md-12">
                      <div
                        className="bg-background text-center"
                        style={{
                          padding: "20px",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      >
                        <h6
                          className="font-semi-bold text-success"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          <MdCheckCircle size={60}/>
                          <br/>
                          Task was reported successfully
                        </h6>
                      </div>
                    </div>
                    :""
                  }
      
                  {
                    (selectedAgentData) ?
                    <div className="col-md-12">
                    
                      <div className="mb-4">
                        <h6
                          className="font-regular"
                          style={{
                            fontSize: "16px"
                          }}
                        >
                          Task Title
                        </h6>

                        <h6
                          style={{
                            fontSize: "24px"
                          }}
                          className="font-semi-bold"
                        >
                          {taskData.title}
                        </h6>
                      </div>

                      <div className="mb-4">
                        <h6
                          className="font-regular"
                          style={{
                            fontSize: "16px"
                          }}
                        >
                          Agent's Name
                        </h6>

                        <h6
                          style={{
                            fontSize: "24px"
                          }}
                          className="font-semi-bold"
                        >
                          {selectedAgentData.fullname}
                        </h6>
                      </div>

                      <div className="mb-4">
                        <h6
                          className="font-regular"
                          style={{
                            fontSize: "16px"
                          }}
                        >
                          Agent's Location
                        </h6>

                        <h6
                          style={{
                            fontSize: "14px"
                          }}
                          className="font-semi-bold"
                        >
                          <span style={{ display: "block" }}>Region: {(JSON.parse(selectedAgentData.location || "{}")?.region?.name) || "N/A"}</span>
                          <span style={{ display: "block" }}>District: {(JSON.parse(selectedAgentData.location || "{}")?.district?.name) || "N/A"}</span>
                          <span style={{ display: "block" }}>Ward/Village: {(JSON.parse(selectedAgentData.location || "{}")?.ward?.name) || "N/A"}</span> 
                        </h6>
                      </div>

                      <div className="row">
                        <div className="col-md-6 mb-2">
                          <label className="form-label">Operator's name*</label>
                          <input
                            className="form-control"
                            value={operatorName}
                            onChange={(e) => {
                              setOperatorName(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-md-6 mb-2">
                          <label className="form-label">Operator's phone*</label>
                          <input
                            className="form-control"
                            value={operatorPhone}
                            onChange={(e) => {
                              setOperatorPhone(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-md-6 mb-2">
                          <label className="form-label">Till Number</label>
                          <input
                            className="form-control"
                            value={tillNumber}
                            onChange={(e) => {
                              setTillNumber(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-md-6 mb-2">
                          <label className="form-label">Bank Account Number</label>
                          <input
                            className="form-control"
                            value={accountNumber}
                            onChange={(e) => {
                              setAccountNumber(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-md-6 mb-2">
                          <label className="form-label">Serial Number</label>
                          <input
                            className="form-control"
                            value={deviceSerialNumber}
                            onChange={(e) => {
                              setDeviceSerialNumber(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-md-6 mb-2">
                          <label className="form-label">Device Type</label>
                          <input
                            className="form-control"
                            value={deviceType}
                            onChange={(e) => {
                              setDeviceType(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-md-6 mb-2">
                          <label className="form-label">Imei 1</label>
                          <input
                            className="form-control"
                            value={deviceImei1}
                            onChange={(e) => {
                              setDeviceImei1(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-md-6 mb-2">
                          <label className="form-label">Imei 2</label>
                          <input
                            className="form-control"
                            value={deviceImei2}
                            onChange={(e) => {
                              setDeviceImei2(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-md-12 mb-2">
                          <label className="form-label">Was the task completed successfully</label>
                          <select
                            className="form-control"
                            value={completed}
                            onChange={(e) => {
                              setCompleted(e.target.value)
                            }}
                          >
                            <option value="yes" >Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>

                        {
                          (completed === 'no') ?
                          <div className="col-md-12 mb-2">
                            <label className="form-label">Reason or Cause for failing to complete this task</label>
                            <textarea
                              className="form-control"
                              value={excuse}
                              onChange={(e) => {
                                setExcuse(e.target.value)
                              }}
                            ></textarea>
                          </div>
                          :""
                        }

                        <div className="col-md-12 mb-2">
                          <label className="form-label">Description</label>
                          <textarea
                            className="form-control"
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value)
                            }}
                          ></textarea>
                        </div>

                        <div className="col-md-12 mb-2 text-end">
                          <button
                            onClick={() => {
                              appContext.activateDialog({
                                message: "This will submit report for this task, continue?",
                                onConfirm: completeTaskForAgent,
                              })
                            }}
                            className="btn btn-primary btn-padded"
                          >
                            Submit Report
                          </button>
                        </div>
                        
                      </div>
                    </div>
                    :
                    <div className="col-md-12">
                      <h6>
                        No agent was selected
                      </h6>
                    </div>
                  }

                </div>
              </div>

            }
            
    
          </div>
          
    
        </div>
      )
    } else {
      return (
        <div 
          className="container"
          
        >
          <div className="mFormType1">
            
            <h6 className="section-title">Report Task</h6>
          
            <div className="body">
              <div className="row">
                
    
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div
                      className="bg-background text-center"
                      style={{
                        padding: "20px",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <h6
                        className="font-semi-bold text-danger"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <MdWarning size={60}/>
                        <br/>
                        You do not have access to report this task
                      </h6>
                    </div>
                  </div>
                </div>

              </div>
            </div>
    
          </div>
          
    
        </div>
      )
    }
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}