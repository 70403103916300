import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import FloatManagementHome from "../views/FloatManagementHome";
import AdminFloatManagementHome from "../views/AdminFloatManagementHome";
import AgentsFloatRequests from "../views/AgentsFloatRequests";
import ManagerFloatManagementHome from '../views/ManagerFloatManagementHome';

export default function FloatManagement(props) {

  const appContext = useContext(AppContext);

  const [ view, setView ] = useState(null);

  async function init() {
    //run initializations here
  }

  function getView() {
    if(
      !appContext.navSubItem &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      if(appContext.currentUserData.role === 'admin' || appContext.currentUserData.role === 'cashier') {
        return <AdminFloatManagementHome/>
      } else if(appContext.currentUserData.role === 'manager') {
        return <ManagerFloatManagementHome/>
      } else {
        return <FloatManagementHome/>
      }
    } else if(
      appContext.navSubItem === 'agents_float_requests' &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      return <AgentsFloatRequests/>
    }

    return null;
  }

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    const _view = getView();
    if(_view) {
      setView(_view);
    }
  }, [ appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem ])

  //return desired view
  return view;
}