import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { MdChevronRight } from "react-icons/md";
import { formatDate, formatMoney } from "../Helpers";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ data, setData ] = useState(props.data);
  const [ individualPerformance, setIndividualPerformance ] = useState(0);
  const [ companyPerformance, setCompanyPerformance ] = useState(0);

  async function init() {
    //run initializations here
    if(data) {
      setIndividualPerformance(calculateIndividualPerformance());
      setCompanyPerformance(calculateCompanyPerformance());
    }
  }

  function calculateCompanyPerformance() {
    let _performance = 0;

    if(data) {
      if(data.agentsList) {
        const _agentsList = JSON.parse(data.agentsList);
        let _totalAgents = _agentsList.length;
        let _totalCompleted = 0;

        for (const _agent of _agentsList) {
          if(_agent.completed) {
            ++_totalCompleted;
          }
        }

        if(_totalCompleted > 0) {
          _performance = ((_totalCompleted/_totalAgents) * 100).toFixed(1);
        }
      }
    }

    return _performance;
  }

  function calculateIndividualPerformance() {
    let _performance = 0;

    if(data) {
      if(data.agentsList) {
        const _agentsList = JSON.parse(data.agentsList);
        let _totalAgents = _agentsList.length;
        let _totalCompleted = 0;

        for (const _agent of _agentsList) {
          if(_agent.completed) {
            if(Number(_agent.completedBy) === Number(appContext.currentUserData.id)) {
              ++_totalCompleted;
            }
          }
        }

        if(_totalCompleted > 0) {
          _performance = ((_totalCompleted/_totalAgents) * 100).toFixed(1);
        }
      }
    }

    return _performance;
  }

  useEffect(() => {
    init();
  }, [ data ])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ])
  
  return (
    <div 
      className="One"
      onClick={() => {
        appContext.navTo({
          item: "side-view",
          subItem: "task-options",
          extraItem: data.id,
        })
      }}
    >
      <h6
        className="font-bold text-primary mb-4"
        style={{
          fontSize: "18px",
          wordWrap: "break-word", // Break long words to wrap them
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal" // Allow text to wrap to the next line
        }}
      >
        {data.title}
        {
          (data.taskType === 'float') ?
          <>
            <span className="mb-2 mt-2 font-light" style={{ display: "block", fontSize: "28px" }}>
              {formatMoney(data.floatTarget)} Tsh
            </span>
            <span className="mb-2 mt-2 font-regular" style={{ display: "block", fontSize:"22px" }}>
              {data.providerData?.name}
            </span>
          </>
          :""
        }
      </h6>

      <div 
        className="d-flex justify-content-between"
        style={{
          maxWidth: "100%",
          width: "100%",
          flexWrap: "nowrap", // Ensure the container doesn't wrap its children
        }}
      >
        <div
          className=""
          style={{
            flexShrink: "1", // Allow the flex item to shrink
            flexGrow: "1",
            minWidth: "0", // Ensure the item can shrink below its content width
          }}
        >
          
          <p
            className="text-muted mt-2"
            style={{
              fontSize: "14px",
              wordWrap: "break-word", // Break long words to wrap them
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal" // Allow text to wrap to the next line
            }}
          >
            From <i>{formatDate(data.startDate).substring(0, 10)}</i> 
            <br/>
            To <i>{formatDate(data.endDate).substring(0, 10)}</i>
            <br/><br/>
            <span 
              className={
                (data.status === 'pending') ?
                "category-badge-sm bg-primary":
                (data.status === 'completed') ?
                "category-badge-sm bg-success text-light" :
                (data.status === 'expired') ?
                "category-badge-sm bg-danger text-light" : "category-badge-sm bg-secondary"
              }
            >
              {data.status}
            </span>
          </p>
        </div>

        <div
          className="row"
          style={{
            flexShrink: "0",
            padding: "0px",
            width: "150px",
          }}
        >
          <div className="col-12">
            <h6 style={{ fontSize: "12px", textTransform: "uppercase" }} className="font-orbitron-bold">Performance</h6>
          </div>

          <div className="col-6">
            <h6
              style={{
                fontSize: "18px"
              }}
              className="font-bold"
            >
              {individualPerformance}<br/>%
            </h6>
            <h6 style={{ fontSize: "12px" }} className="font-semi-bold">You</h6>
          </div>

          <div className="col-6">
            <h6
              style={{
                fontSize: "18px"
              }}
              className="font-bold"
            >
              {companyPerformance}<br/>%
            </h6>
            <h6 style={{ fontSize: "12px" }} className="font-semi-bold">All</h6>
          </div>

        </div>
      </div>

    </div>
  )
}