import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import OneTask from '../ones/OneTask';
import OneBranch from '../ones/OneBranch';
import BranchOptions from '../views/BranchOptions';
import StaffOptions from '../views/StaffOptions';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ ready, setReady ] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    if(
      appContext.currentUserData.role === 'admin' ||
      appContext.currentUserData.role === 'cashier' 
    ) {
      await appContext.getAllBranches();
      await appContext.getAllStaff();
      await appContext.getAllTasks();
      await appContext.getTotalIncome();
      await appContext.getTotalExpenses();
    } else if(
      appContext.currentUserData.role === 'tpsf' 
    ) {
      await appContext.getMyTasks();
      await appContext.getMyBranchData();
    } else if(
      appContext.currentUserData.role === 'agent' 
    ) {
      await appContext.getMyFloatRequests();
      await appContext.getMyCashRequests()
    }
    
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [ ])

  if(ready) {
    if(
      appContext.currentUserData.role === 'admin' ||
      appContext.currentUserData.role === 'cashier' 
    ) {
      return (
        <div 
          className="container"
        >
          <div className="row">
            <div className="col-md-12">
              <h6 className="main-section-title">
                Hello, {appContext.currentUserData.fullname}
              </h6>
              <h6
                style={{
                  fontSize: "18px"
                }}
                className="mb-4"
              >
                Role: <span className="font-bold">{appContext.currentUserData.role}</span>
              </h6>
            </div>

            <div className="col-md-6">
              <div 
                className="card"
                onClick={() => {
                  appContext.navTo({
                    item: 'branches'
                  })
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div className="card-body">
                  <div className="d-flex">
                    <PiBuildingOfficeBold
                      className="align-self-center mNoMargin text-secondary"
                      size={40}
                      style={{
                        flexShrink: "0",
                      }}
                    />
    
                    <div
                      className="align-self-center mNoMargin"
                      style={{
                        flexGrow: "1",
                        paddingLeft: "10px"
                      }}
                    >
                      <h6 style={{ fontSize: "14px" }} className="text-muted">Branches</h6>
                      <h6 
                        style={{ 
                          fontSize: "24px" ,
                        }} 
                        className="font-bold mOneLineText">
                        {appContext.allBranches.length}
                      </h6>
                    </div>
    
                  </div>
                </div>
              </div>
            </div>
    
            <div className="col-md-6">
              <div 
                className="card"
                onClick={() => {
                  appContext.navTo({
                    item: 'staff'
                  })
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div className="card-body">
                  <div className="d-flex">
                    <SiGoogleclassroom
                      className="align-self-center mNoMargin text-secondary"
                      size={40}
                      style={{
                        flexShrink: "0",
                      }}
                    />
    
                    <div
                      className="align-self-center mNoMargin"
                      style={{
                        flexGrow: "1",
                        paddingLeft: "10px"
                      }}
                    >
                      <h6 style={{ fontSize: "14px" }} className="text-muted">Staff</h6>
                      <h6 
                        style={{ 
                          fontSize: "24px" ,
                        }} 
                        className="font-bold mOneLineText">
                        {appContext.allStaff.length}
                      </h6>
                    </div>
    
                  </div>
                </div>
              </div>
            </div>
    
    
            <div className="col-md-12">
              <div className="row">
    
                <div className="col-md-6">
                  <div 
                    className="card"
                    onClick={() => {
                      appContext.navTo({
                        item: 'accounting',
                      })
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="main-section-title font-semi-bold">
                        {formatMoneyShorten(appContext.totalIncome)} Tsh
                      </h6>
                      <h6
                        className="font-bold"
                        style={{
                          fontSize: "12px"
                        }}
                      >
                        Total Income
                      </h6>
                    </div>
                  </div>
                </div>
    
                <div className="col-md-6">
                  <div 
                    className="card"
                    onClick={() => {
                      appContext.navTo({
                        item: 'accounting',
                      })
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="main-section-title font-semi-bold">
                        {formatMoneyShorten(appContext.totalExpenses)} Tsh
                      </h6>
                      <h6
                        className="font-bold"
                        style={{
                          fontSize: "12px"
                        }}
                      >
                        Total Expenses
                      </h6>
                    </div>
                  </div>
                </div>

                {
                  (appContext.currentUserData.role === 'admin') ?
                  <div className="col-md-12">
                    <div 
                      className="card"
                    >
                      <div className="card-body">
                        <h6 className="main-section-title">Recent Tasks</h6>
                        {
                          (appContext.allTasks && appContext.allTasks.length > 0) ?
                          appContext.allTasks.slice(0, 2).map((item, i) => {
                            return (
                              <OneTask key={i} data={item}/>
                            )
                          })
                          :
                          <h6
                            className=""
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            No task was found
                          </h6>
                        }
                      </div>
                      
                    </div>
                  </div>
                  :""
                }
    
              </div>
            </div>
  
    
          </div>
    
        </div>
      )
    } else if(
      appContext.currentUserData.role === 'tpsf'
    ) {
      return (
        <div 
          className="container"
        >
          <div className="row">
            <div className="col-md-12" style={{ padding: "0" }}>
              <StaffOptions id={appContext.currentUserData.id} />
            </div>
    
          </div>
    
        </div>
      )
    } else if(
      appContext.currentUserData.role === 'agent'
    ) {
      return (
        <div 
          className="container"
        >
          <div className="row">
            <div className="col-md-12" style={{ padding: "0" }}>
              <StaffOptions id={appContext.currentUserData.id} />
            </div>
    
          </div>
    
        </div>
      )
    } else if(
      appContext.currentUserData.role === 'manager'
    ) {
      return (
        <div 
          className="container"
        >
          <div className="row">
            <div className="col-md-12">
              <h6 className="main-section-title">
                Hello, {appContext.currentUserData.fullname}
              </h6>
              <h6
                style={{
                  fontSize: "18px"
                }}
                className="mb-4"
              >
                Role: <span className="font-bold">{appContext.currentUserData.role}</span>
              </h6>
            </div>
    
            <div className="col-md-12 mNoPadding">
              <BranchOptions id={appContext.currentUserData.branchId}/>
            </div>
            
    
          </div>
    
        </div>
      )
    }
  } else {
    return (
      <div 
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }

  
}