import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ title, setTitle ] = useState("");
  const [ startDate, setStartDate ] = useState("");
  const [ providerId, setProviderId ] = useState("");
  const [ endDate, setEndDate ] = useState("");
  const [ agentsList, setAgentsList ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ branchId, setBranchId ] = useState(props.branchId || "");
  const [ taskType, setTaskType ] = useState("other");
  const [ floatTarget, setFloatTarget ] = useState(0);
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  async function init() {
    //run initializations here if any
    appContext.getAllBranches(); //get all branches in the background
    appContext.getSupportedProviders();
  }

  function formatAgentsList(input) {
    if(input) {
      let formated = input.trim().replace(/\n/g, ' ');
      return formated;
    }

    return "";
  }

  function resetInputFields() {
    setTitle("");
    setStartDate("");
    setEndDate("");
    setAgentsList("");
  }

  async function create() {
    if(!loading) {
      if(title.trim().length > 0) {
        if(startDate.trim().length > 0) {
          if(endDate.trim().length > 0) {
            if(String(branchId).trim().length > 0) {
              if(taskType === 'other' || (taskType === 'float' && !isNaN(floatTarget) && Number(floatTarget) > 0 && providerId)) {
                //..
                setLoading(true);
                await callApi(
                  "create_task.php",
                  {
                    title,
                    startDate,
                    floatTarget,
                    taskType,
                    endDate,
                    agentsList,
                    branchId,
                    providerId,
                  }
                ).then(response => {
                  if(response.status === 1) {
                    if(props.branchId) {
                      appContext.getBranchTasks();
                    } else {
                      appContext.getAllTasks();
                    }
                    setOpSuccessful(true);
                    resetInputFields();
                    appContext.tellMessage("Task was created");
                  } else {
                    appContext.tellError(response.msg);
                  }
                });
                setLoading(false);
                //..
              } else {
                appContext.tellError("Invalid float target or provider");
              }
            } else {
              appContext.tellError("Invalid branch");
            }
          } else {
            appContext.tellError("Invalid end date");
          }
        } else {
          appContext.tellError("Invalid start date");
        }
      } else {
        appContext.tellError("Invalid task title");
      }
    } else {
      appContext.tellInfo("Loading, please wait....")
    }
  }

  

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  return (
    <div 
      className="container"
      
    >
      <div className="mFormType1">
        
        <h6 className="section-title">Create Task</h6>
      
        <div className="body">
          <div className="row">
            {
              (opSuccessful) ?
              <div className="col-md-12">
                <div
                  className="bg-background text-center"
                  style={{
                    padding: "20px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <h6
                    className="font-semi-bold text-success"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    <MdCheckCircle size={60}/>
                    <br/>
                    Task was created successfully
                  </h6>
                </div>
              </div>
              :""
            }

            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Task Type*</label>
                <select
                  type="text"
                  className="form-control"
                  value={taskType}
                  onChange={(e) => {
                    setTaskType(e.target.value);
                    setOpSuccessful(false);
                  }}
                >
                  <option value="other">Other</option>
                  <option value="float">Float Serving</option>
                </select>
              </div>
              
              {
                (taskType === 'float') ?
                <>
                  <div className="mb-3">
                    <label className="form-label">Float target for each agent*</label>
                    <input
                      type="text"
                      className="form-control"
                      value={floatTarget}
                      onChange={(e) => {
                        setFloatTarget(e.target.value);
                        setOpSuccessful(false);
                      }}
                    />
                    <div className="form-text">In TZS</div>
                  </div>

                  <div className="mb-3">
                    <label className="">Select Provider</label>
                    <select
                      className="form-control"
                      value={providerId}
                      onChange={(e) => setProviderId(e.target.value)}
                    >
                      <option value=""></option>
                      {
                        (appContext.supportedProviders && appContext.supportedProviders.length > 0) ?
                        appContext.supportedProviders.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          )
                        }):""
                      }
                    </select>
                  </div>
                </>
                :""
              }

              <div className="mb-3">
                <label className="form-label">Task title*</label>
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setOpSuccessful(false);
                  }}
                />
                <div className="form-text">Title for this task</div>
              </div>

              <div className="mb-3 col-12">
                <label className="form-label">Branch</label>
                <select
                  type="text"
                  className="form-control"
                  value={branchId}
                  onChange={(e) => {
                    setBranchId(e.target.value);
                    setOpSuccessful(false);
                  }}
                  disabled={(props.branchId) ? true : false}
                >
                  <option value=""></option>
                  {
                    (appContext.allBranches && appContext.allBranches.length) ?
                    appContext.allBranches.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>{item.name}</option>
                      )
                    })
                    :""
                  }
                </select>
              </div>

              <div className="mb-3">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Start Date*</label>
                    <input
                      type="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        setOpSuccessful(false);
                      }}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">End Date*</label>
                    <input
                      type="date"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        setOpSuccessful(false);
                      }}
                    />
                  </div>
                  
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Targeted Agents for this task</label>
                <textarea
                  type="text"
                  className="form-control"
                  value={agentsList}
                  onChange={(e) => {
                    setAgentsList(formatAgentsList(e.target.value));
                    setOpSuccessful(false);
                  }}
                  rows={4}
                ></textarea>
                <div className="form-text">Provide a list of comma or space separated phone numbers for agents targeted for this tasks. These agents should already have been registered in the system</div>
              </div>

            </div>

            <div className="col-md-12 text-end">
              <button
                className="btn btn-primary btn-padded mSupportLoading"
                onClick={() => {
                  appContext.activateDialog({
                    message: `This task will be created, continue?`,
                    onConfirm: create,
                  })
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>

      </div>
      

    </div>
  )
}