import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { MdChevronRight } from "react-icons/md";
import { formatDate, formatMoney } from "../Helpers";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ data, setData ] = useState(props.data);

  async function init() {
    //run initializations here
  }

  useEffect(() => {
    init();
  }, [ data ])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ])
  
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">

          <div className="col-12 mb-3">
            <h6
              style={{
                fontSize: "18px",
              }}
              className="font-regular text-primary"
            >
              {data.title}
            </h6>

            <span
              className="category-badge-sm bg-primary"
            >
              {data.providerData?.name}
            </span>
          </div>

          <div className="col-6 col-md-6 mb-3">
            <h6
              style={{
                fontSize: "16px",
              }}
              className=""
            >
              {formatDate(data.startDate).substring(0, 10)}
            </h6>
            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>From</h6>
          </div>

          <div className="col-6 col-md-6 mb-3">
            <h6
              style={{
                fontSize: "16px",
              }}
              className=""
            >
              {formatDate(data.endDate).substring(0, 10)}
            </h6>
            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>To</h6>
          </div>

          <div className="col-6 col-md-6 mb-3">
            <h6
              style={{
                fontSize: "16px",
              }}
              className=""
            >
              {formatMoney(data.amount)}
            </h6>
            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Float Goal (Tsh)</h6>
          </div>

          <div className="col-6 col-md-6 mb-3">
            <h6
              style={{
                fontSize: "16px",
              }}
              className=""
            >
              {formatMoney(data.totalFloatServed)}
            </h6>
            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Float Served (Tsh)</h6>
          </div>

          <div className="col-6 col-md-6 mb-3">
            <h6
              style={{
                fontSize: "16px",
              }}
              className=""
            >
              {data.percentageCompletion}%
            </h6>
            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Perfomance</h6>
          </div>

          <div className="col-6 col-md-6 mb-3">
            <h6
              style={{
                fontSize: "16px",
              }}
              className=""
            >
              {data.numberOfTransactions}
            </h6>
            <h6 className="font-semi-bold" style={{ fontSize: "12px" }}>Number of transactions</h6>
          </div>

        </div>
      </div>
    </div>
  )
}

