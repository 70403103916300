import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import OneBranch from '../ones/OneBranch';
import { MdSearch } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const[ ready, setReady ] = useState(false);

  const [ branchesCount, setBranchesCount ] = useState(0);
  const [ filter, setFilter ] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getAllBranches();
    setReady(true);
  }


  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(appContext.allBranches) {
      setBranchesCount(appContext.allBranches.length);
    }
  }, [ appContext.allBranches ])
  
  if(ready) {
    return (
      <div 
        className="container"
      >
        <div className="card">
          <div
            className="bg-primary"
            style={{
              width: "100%",
              padding: "20px",
              paddingTop: "calc(var(--topBarHeight) * 0.5)",
              paddingBottom: "calc(var(--topBarHeight) * 0.5)",
              height: "auto",
            }}
          >
            <div className="row">
              
              <div className="col-md-4 col-6">
                <h6
                  className="font-light"
                  style={{
                    fontSize: "48px",
                    marginTop: "30px",
                  }}
                >
                  {branchesCount}
                </h6>
                <h6 className="font-semi-bold" style={{ fontSize: "14px" }}>Branches</h6>
              </div>

  
            </div>
          </div>
  
          <div className="card-body">
            <h1 className="main-section-title">Branches</h1>

            <div className="text-end mb-3">
              <button
                className="btn btn-padded btn-outline-primary"
                onClick={() => {
                  appContext.navTo({
                    item: 'view',
                    subItem: 'register-branch'
                  })
                }}
              >
                Register Branch
              </button>
            </div>

            <div 
              className="d-flex"
              
            >
              <MdSearch size={30} className="align-self-center"/>
              <input
                className="form-control"
                style={{
                  width: "250px",
                }}
                placeholder="filter by name"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>

            <hr/>
            
            
            {
              (appContext.allBranches && appContext.allBranches.length > 0) ?
              appContext.allBranches.map((item, i) => {
                
                if(filter && filter.trim().length > 0) {
                  if(
                    String(item.name).toUpperCase().indexOf(String(filter).toUpperCase()) > -1
                  ) {
                    return (
                      <OneBranch key={i} data={item}/>
                    )
                  } else {
                    return "";
                  }
                } else {
                  return (
                    <OneBranch key={i} data={item}/>
                  )
                }
              })
              :
              <h6
                className=""
                style={{
                  fontSize: "14px",
                }}
              >
                No branches were found
              </h6>
            }
          </div>
        </div>
  
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }
}