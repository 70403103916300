import { useContext } from "react"
import { AppContext } from "../App"
import logo from '../assets/logo.png'
import logo_worded from '../assets/logo_worded.png'
import { MdDashboard, MdHome, MdOutlineAnalytics, MdOutlineDashboard, MdOutlinePayment, MdOutlinePayments, MdOutlineSpaceDashboard, MdPayments, MdSubject } from "react-icons/md";
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { GoPerson } from "react-icons/go";


export default function SideBar(props) {

  const appContext = useContext(AppContext);

  

  return (
    <div 
      className="SideBar"
      data-open={appContext.isSideBarOpen}
    >
      <div className="body mNoScrollBar">

        <div className="logo-holder">
          <div className="d-flex">
            <img
              src={logo}
              width="auto"
              height="50px"
              className="align-self-center"
            />
            <h6
              className="font-orbitron-bold text-secondary mNoMargin align-self-center"
              style={{
                fontSize: "16px",
                paddingLeft: "5px",
                paddingTop: "0px",
                borderLeft: "1px solid var(--onPrimaryColor)"
              }}
            >
              Balwyn Investment Group
            </h6>
          </div>
          
        </div>

        {
          (
            appContext.currentUserData.role === 'admin'
          ) ?
          <>
            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'dashboard'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'dashboard' || !appContext.navItem)}
            >
              <MdOutlineDashboard className="icon"/>
              <p className="text">Dashboard</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'branches'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'branches')}
            >
              <PiBuildingOfficeBold className="icon"/>
              <p className="text">Branches</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'staff'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'staff')}
            >
              <SiGoogleclassroom className="icon"/>
              <p className="text">Staff</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'tasks'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'tasks')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">All Tasks</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'float_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'float_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Float Management</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'cash_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'cash_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Cash Management</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'accounting'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'accounting')}
            >
              <MdOutlineAnalytics className="icon"/>
              <p className="text">Accounting & Finance</p>
            </div>

          </>:""
        }

        {
          (
            appContext.currentUserData.role === 'cashier'
          ) ?
          <>
            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'dashboard'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'dashboard' || !appContext.navItem)}
            >
              <MdOutlineDashboard className="icon"/>
              <p className="text">Dashboard</p>
            </div>

            

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'float_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'float_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Float Management</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'cash_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'cash_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Cash Management</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'accounting'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'accounting')}
            >
              <MdOutlineAnalytics className="icon"/>
              <p className="text">Accounting & Finance</p>
            </div>

          </>:""
        }

        {
          (
            appContext.currentUserData.role === 'manager'
          ) ?
          <>
            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'dashboard'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'dashboard' || !appContext.navItem)}
            >
              <MdOutlineDashboard className="icon"/>
              <p className="text">Dashboard</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'tasks'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'tasks')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">All Tasks</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'float_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'float_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Float Management</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'cash_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'cash_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Cash Management</p>
            </div>

          </>:""
        }

        {
          (
            appContext.currentUserData.role === 'tpsf'
          ) ?
          <>
            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'dashboard'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'dashboard' || !appContext.navItem)}
            >
              <MdOutlineDashboard className="icon"/>
              <p className="text">Dashboard</p>
            </div>

          
            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'my_tasks'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'my_tasks')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">My Tasks</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'float_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'float_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Float Management</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'cash_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'cash_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Cash Management</p>
            </div>

          </>:""
        }


        {
          (
            appContext.currentUserData.role === 'agent'
          ) ?
          <>
            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'dashboard'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'dashboard' || !appContext.navItem)}
            >
              <MdOutlineDashboard className="icon"/>
              <p className="text">Dashboard</p>
            </div>

            
            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'float_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'float_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Float Management</p>
            </div>

            <div 
              className="pill"
              onClick={() => {
                appContext.navTo({
                  item: 'cash_management'
                })
                appContext.setIsSideBarOpen(false);
              }}
              data-active={(appContext.navItem === 'cash_management')}
            >
              <MdOutlinePayments className="icon"/>
              <p className="text">Cash Management</p>
            </div>

          </>:""
        }

        
        {/*
        <div 
          className="pill"
          onClick={() => {
            appContext.navTo({
              item: 'payments'
            })
            appContext.setIsSideBarOpen(false);
          }}
          data-active={(appContext.navItem === 'payments')}
        >
          <MdSubject className="icon"/>
          <p className="text">Reports</p>
        </div>

        <div 
          className="pill"
          onClick={() => {
            appContext.navTo({
              item: 'more'
            })
            appContext.setIsSideBarOpen(false);
          }}
          data-active={(appContext.navItem === 'more')}
        >
          <MdOutlineSpaceDashboard className="icon"/>
          <p className="text">Payroll</p>
        </div>

        <div 
          className="pill"
          onClick={() => {
            appContext.navTo({
              item: 'account'
            })
            appContext.setIsSideBarOpen(false);
          }}
          data-active={(appContext.navItem === 'account')}
        >
          <GoPerson className="icon"/>
          <p className="text">Account Settings</p>
        </div>
        */}


      </div>
    </div>
  )
}