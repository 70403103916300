import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { callApi, getInlineLoader } from "../Helpers";
import { MdCheck, MdCheckCircle, MdWarning } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ amount, setAmount ] = useState(0);
  const [ providerId, setProviderId ] = useState("");
  const [ receiverPhone, setReceiverPhone ] = useState("");
  const [ receiverType, setReceiverType ] = useState("staff");
  const [ receiptText, setReceiptText ] = useState("");
  const [ receiverData, setReceiverData ] = useState(null);
  const [ isReceiverDataLoading, setIsReceiverDataLoading ] = useState(false);
  
  const [ opSuccessful, setOpSuccessful ] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getSupportedProviders();
    await appContext.getAllBranches();
    setReady(true);
  }

  function resetFields() {
    setAmount(0);
    setProviderId("");
    setReceiverPhone("");
    setReceiptText("");
    setReceiverType("staff");
    setReceiverData(null);
    setOpSuccessful(false);
  }

  async function getReceiverData() {
    setIsReceiverDataLoading(true);
    if(receiverType === 'staff') {
      await callApi("get_staff_data_by_phone.php", { phone: receiverPhone }).then(response => {
        if(response.status === 1) {
          setReceiverData(response.data);
        } else {
          setReceiverData(null);
        }
      })
    } else if(receiverType === 'branch') {
      await callApi("get_branch_data.php", { id: receiverPhone }).then(response => {
        if(response.status === 1) {
          setReceiverData(response.data);
        } else {
          setReceiverData(null);
        }
      })
    }
    setIsReceiverDataLoading(false);
  }

  async function sendCash() {
    if(!loading) {
      const receiptFile = document.getElementById("_receiptFile").files[0];
      if(!isNaN(amount) && Number(amount) > 0) {
        if(String(providerId).trim().length > 0) {
          if(receiverData && String(receiverPhone).trim().length > 0) {
            if(receiptText.trim().length > 0 || receiptFile || 1 == 1) {
              //..
              setLoading(true);
              await callApi(
                'record_transaction.php',
                {
                  fromDesc: appContext.currentUserData.role,
                  toDesc: (receiverType === 'staff') ? receiverData.role : 'main',
                  fromId: appContext.currentUserData.id,
                  toId: receiverData.id,
                  receiptText,
                  receiptFile,
                  amount,
                  transactionType: "cash",
                  providerId,
                }
              ).then((response) => {
                if(response.status === 1) {
                  setOpSuccessful(true);
                  appContext.getMyCashRequests();
                  appContext.tellMessage("Request sent!");
                } else {
                  appContext.tellError(response.msg);
                }
              });
              setLoading(false);
              //..
            } else {
              appContext.tellError("Either receipt text or file is required");
            }
          } else {
            appContext.tellError("Invalid receiver");
          }

        } else {
          appContext.tellError("Invalid provider selection");
        }
      } else {
        appContext.tellError("Invalid amount");
      }
    } else {
      appContext.tellWarning("Loading, Please wait....")
    }
  }

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    getReceiverData();
  }, [ receiverPhone ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  if(ready) {
    return (
      <div 
        className="container"
        
      >
        <div className="mFormType1">
          
          <h6 className="section-title">Send Cash</h6>
        
          <div className="body">
            <div className="row">
              {
                (opSuccessful) ?
                <div className="col-md-12">
                  <div
                    className="bg-background text-center"
                    style={{
                      padding: "20px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <h6
                      className="font-semi-bold text-success"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      <MdCheckCircle size={60}/>
                      <br/>
                      Transaction was recorded successfully

                      <hr/>

                      <button
                        className="btn btn-padded btn-secondary"
                        onClick={resetFields}
                      >
                        Send Again
                      </button>
                    </h6>
                  </div>
                </div>
                :
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="">Sending As</label>
                    <h6
                      className="font-regular"
                      style={{
                        fontSize: "24px",
                        textTransform: "capitalize",
                      }}
                    >
                      {appContext.currentUserData.role}
                    </h6>
                  </div>

                  <div className="mb-3">
                    <label className="">Select Provider</label>
                    <select
                      className="form-control"
                      value={providerId}
                      onChange={(e) => setProviderId(e.target.value)}
                    >
                      <option value=""></option>
                      {
                        (appContext.supportedProviders && appContext.supportedProviders.length > 0) ?
                        appContext.supportedProviders.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          )
                        }):""
                      }
                    </select>
                  </div>

                  <div className="mb-3">
                    <label>Amount</label>
                    <input
                      className="form-control"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label>Receipt text</label>
                    <textarea
                      className="form-control"
                      value={receiptText}
                      onChange={(e) => setReceiptText(e.target.value)}
                    ></textarea>
                    <div className="form-text">Receipt in text form</div>
                  </div>

                  <div className="mb-3">
                    <label>Receipt file</label>
                    <input
                      className="form-control"
                      type="file"
                      accept="image/*"
                      id="_receiptFile"
                    />
                    <div className="form-text">Image of a receipt</div>
                  </div>

                  {
                    (appContext.currentUserData.role === 'tpsf') ?
                    <div className="mb-3">
                      <label className="">Receiver Type</label>
                      <select
                        className="form-control"
                        value={receiverType}
                        onChange={(e) => setReceiverType(e.target.value)}
                      >
                        <option value="staff">Staff</option>
                        <option value="branch">Branch</option>
                      </select>
                    </div>
                    :""
                  }

                  {
                    (receiverType === 'staff') ?
                    <div className="mb-3">
                      <label>Receiver's Phone</label>
                      <input
                        className="form-control"
                        value={receiverPhone}
                        maxLength={10}
                        onChange={(e) => setReceiverPhone(e.target.value)}
                      />
                      <div className="form-text">Phone number of a registered member</div>

                      <div 
                        className="mSupportLoading" 
                        style={{ 
                          width: "100%",
                          borderLeft: "5px solid var(--primaryColor)",
                          padding: "10px"
                        }}
                      >
                        {
                          (isReceiverDataLoading) ?
                          getInlineLoader()
                          :
                          (receiverData) ?
                          <>
                            <h6
                              style={{
                                fontSize: "18px",
                              }}
                              className="font-regular"
                            >
                              {receiverData.fullname}
                              <span
                                style={{
                                  fontSize: "12px",
                                  display: "block",
                                }}
                                className="font-semi-bold"
                              >
                                {receiverData.role}
                              </span>
                            </h6>
                          </>
                          :""
                        }
                      </div>
                    </div>
                    :""
                  }

                  {
                    (receiverType === 'branch') ?
                    <div className="mb-3 col-12">
                      <label className="form-label">Select Branch</label>
                      <select
                        type="text"
                        className="form-control"
                        value={receiverPhone}
                        onChange={(e) => {
                          setReceiverPhone(e.target.value);
                          setOpSuccessful(false);
                        }}
                        disabled={(props.branchId) ? true : false}
                      >
                        <option value=""></option>
                        {
                          (appContext.allBranches && appContext.allBranches.length) ?
                          appContext.allBranches.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>{item.name}</option>
                            )
                          })
                          :""
                        }
                      </select>
                    </div>
                    :""
                  }

                  <div className="mb-3 text-end">
                    <button
                      className="btn btn-padded mSupportLoading btn-primary"
                      onClick={() => {
                        appContext.activateDialog({
                          message: "Are you sure you want to send cash to this member?",
                          onConfirm: sendCash,
                        })
                      }}
                    >
                      {(loading) ? getInlineLoader() : "Send Cash"}
                    </button>
                  </div>
                  
                </div>
              }
              
            </div>
          </div>
  
        </div>
        
  
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoadingFull"
      >
        {getInlineLoader()}
      </div>
    )
  }
}