import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import OneCashRequest from '../ones/OneCashRequest';
import { formatDate } from "../Helpers";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ ready, setReady ] = useState(false);
  const [ allRecords, setAllRecords ] = useState(null);
  

  async function init() {
    //run initializations here
    setReady(false);
    await getAllRecords();
    await appContext.getTotalExpenses();
    setReady(true);
  }

  async function getAllRecords() {
    await callApi("get_all_expense_records.php", {}).then(response => {
      if(response.status === 1) {
        setAllRecords(response.data);
      }
    })
  }


  useEffect(() => {
    init();
  }, [ ])

  
  if(ready) {
    return (
      <div 
        className="container"
      >
        <div 
          className="card"
        >
          <div className="card-body">
            <div className="text-end mb-3">
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => {
                  appContext.navTo({
                    item: 'view',
                    subItem: 'record-expense'
                  })
                }}
              >
                Record Expense
              </button>

              
              <hr/>
            </div>

            <h1 className="main-section-title">Expense Records</h1>

            <div className="mb-4">
              <h4
                className="font-semi-bold"
              >
                Total = {formatMoney(appContext.totalExpenses)} Tsh
              </h4>
            </div>
            

            <hr/>            
            {
              (allRecords && allRecords.length > 0) ?
              <table
                className="table table-striped"
              >
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    allRecords.map((item, i) => {
                      return (
                        <tr
                          key={i}
                        >
                          <td>{item.title}</td>
                          <td>
                            {formatMoney(item.amount)}
                          </td>
                          <td>
                            {formatDate(item.recordDate).substring(0, 10)}
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              :
              <h6
                className=""
                style={{
                  fontSize: "14px",
                }}
              >
                No records were found
              </h6>
            }
          </div>
        </div>
  
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }
}